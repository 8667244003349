export default function scrollToTarget(element, bool = true) {
  let headerOffset = element.getBoundingClientRect().top / 10
  let elementPosition = element.getBoundingClientRect().top

  let offsetPosition = elementPosition - headerOffset

  window.scrollTo({
    top: bool ? offsetPosition : elementPosition,
    behavior: "smooth",
  })
}
