import React from "react"
import Jumbotron from "../components/Jumbotron/Jumbotron"
import Layout from "../components/Layout"
import HomeValueProps from "../components/HomeValueProps/HomeValueProps"
import HomeWhyChooseUs from "../components/HomeWhyChooseUs/HomeWhyChooseUs"
import HomeProductTypes from "../components/HomeProductTypes/HomeProductTypes"
import HomeSuccessSection from "../components/HomeSuccessSection/HomeSuccessSection"
import { graphql, useStaticQuery } from "gatsby"
import HelmetComponent from "../components/Helmet"
import useIsClient from "../hooks/rehydration"

// import Loader from "../components/Loader/Loader"
function Index() {
  const { isClient } = useIsClient()

  const data = useStaticQuery(graphql`
    {
      allContentfulSeoEach(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            id
            title
            url
            description
            facebookImage {
              fixed {
                srcWebp
              }
            }
          }
        }
      }
      allContentfulJumbotronPage(
        sort: { fields: createdAt }
        limit: 1
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            landingButtonText
            landingHeader
            dialog
            landingDevices {
              title
              file {
                url
              }
            }
            astronautStatic {
              file {
                url
              }
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            landingParagraph {
              landingParagraph
            }
          }
        }
      }
      allContentfulValuePropsMini(
        sort: { fields: id }
        filter: { node_locale: { eq: "hu" } }
      ) {
        nodes {
          miniPara
          miniSvg {
            title
            file {
              url
            }
          }
          miniTitle
          rotate
          isRocket
          smallMobileUnderline
        }
      }
      allContentfulValuePropsMain(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          mainSvg {
            title
            file {
              url
            }
          }
          mainTitle
          mainPara {
            mainPara
          }
        }
      }
      WhyChooseUs: allContentfulJumbotronPage(
        sort: { fields: createdAt }
        skip: 1
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            astronautStatic {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            landingDevices {
              file {
                url
              }
              title
            }
            landingButtonText
            landingHeader
            landingParagraph {
              landingParagraph
            }
          }
        }
      }
      allContentfulChooseUsCards(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          svg {
            title
            file {
              url
            }
          }
          para {
            para
          }
          title
        }
      }
      allContentfulProductTypesMain(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          svg {
            file {
              url
            }
            title
          }
          title
        }
      }
      allContentfulProductTypes(
        sort: { fields: createdAt }
        filter: { node_locale: { eq: "hu" } }
      ) {
        nodes {
          title
          para {
            para
          }
          anchorTagLinks
        }
      }
      allContentfulSuccessPage(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          buttonText
          textPlace
          mailPlace
          nevPlace
          webmindEmail
          webmindTel
          adatkezeles
          astronaut {
            title
            file {
              url
            }

            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          contactButtonText
          contactEmailPlaceholder
          contactNameMessage
          contactNamePlaceholder
          title
          mainpara {
            mainpara
          }
          # secondpara {
          #   secondpara
          # }
        }
      }
    }
  `)
  let seo = data.allContentfulSeoEach.edges[0].node
  if (!isClient) return <HelmetComponent seo={seo} />
  return (
    <Layout>
      <HelmetComponent seo={seo} />
      <Jumbotron data={data} />
      <HomeValueProps data={data} />
      <HomeWhyChooseUs data={data} />
      <HomeProductTypes data={data} />
      <HomeSuccessSection data={data} />
    </Layout>
  )
}

export default Index
