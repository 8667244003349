import React from "react"

import { H2, P, buttonBckg, LandingButton } from "../../globalStyles"
import styled from "styled-components"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

function SaleItems(props) {
  const { title, para, dark, link } = props

  return (
    <>
      <Col
        top
        dark={dark}
        className="col-10  col-sm-8  col-md-4   col-lg-4  justify-content-center  "
      >
        <Link style={{ textDecoration: "none" }} to={link}>
          <div className="row ">
            <Col
              titleBoolean={"true"}
              className="col-12  mb-0 mb-lg-2 text-center"
            >
              <H2
                dark={dark}
                values={"true"}
                services
                style={{
                  //

                  color: dark ? buttonBckg.darkHover : "white",
                  fontWeight: "bold",
                  //
                  display: "inline-block",
                }}
              >
                {title}
              </H2>
            </Col>

            <Col ref={props.myRef} textbox className="col-12  mb-3 text-center">
              <P style={{ color: dark ? "#505050" : "white" }}>{para}</P>
              {/* <LandingButton>Ez érdekel!</LandingButton> */}
              <LandingButton dark={!dark}>Bővebben</LandingButton>
            </Col>
          </div>
        </Link>
      </Col>
    </>
  )
}

export const Col = styled.div`
  display: inline-block;
  cursor: pointer;

  transition: all 300ms;

  margin-left: auto;
  margin-right: auto;

  border-radius: 8px;

  @media (max-width: 640px) {
    margin-top: ${({ top }) => top && "6%"};
  }

  @media (min-width: 641px) {
    margin-top: ${({ top }) => top && "5%"};
    margin-bottom: ${({ top }) => top && "5%"};
  }

  @media (min-width: 1024px) {
    margin-top: ${({ top }) => top && "3%"};
    margin-bottom: ${({ top }) => top && "3%"};
  }

  ${({ titleBoolean }) =>
    titleBoolean &&
    `
overflow-wrap: break-word;

    border-radius: 15px 15px 0px 0px;
    padding:8px;
    
 `}
  ${({ textbox }) =>
    textbox &&
    `
opacity:0;
    
 `}

  ${({ mid }) =>
    mid &&
    `
    border:1px solid red;
    height:auto;
    margin-top:6%;
  overflow-wrap: break-word;
  width:20%;
  margin-left:-40px;
  margin-right:-40px;

  
`}
${({ top }) =>
    top &&
    ` 

    height:auto;
max-width:440px;

border: 1px solid transparent;
    border-radius:15px;
//     box-shadow: 0px 5px 10px 0px rgba(41, 125, 245,0.3);
//     box-shadow:  0px 2px 5px 2px rgba(41, 125, 245,0.15);

// &:hover{
//   transform: translateY(-5px);
//     box-shadow: 0px 5px 10px 0px rgba(41, 125, 245,0.3);
// }


`}

${({ dark, top }) =>
    !dark &&
    top &&
    `
    // background:rgba(61, 175, 245,0.5);
    // background:rgba(85,89,257,.5);
    
`}
`

export default SaleItems
