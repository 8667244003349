import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
function ChooseAstronaut(props) {
  const { astronaut, planets } = props
  const [neptune, satellite, saturn, pluto, jupiter, blackhole, slime] = planets

  return (
    <AstroDiv className="col-12 col-md-6 col-lg-5 align-content-center  mx-auto ">
      <ChooseImg
        width={"10%"}
        positions={["5%", "0", "0", "65%"]}
        black
        src={blackhole.file.url}
        alt={blackhole.title}
      />
      <ChooseImg
        width={"16%"}
        positions={["50%", "0px", "10px", "10px"]}
        src={neptune.file.url}
        neptune
        alt={neptune.title}
      />
      <ChooseImg
        positions={["10%", "0px", "10px", "25%"]}
        src={pluto.file.url}
        alt={pluto.title}
        pluto
      />
      <ChooseImg
        positions={["calc(30vh/2)", "0", "10px", "80%"]}
        src={jupiter.file.url}
        jupiter
        alt={jupiter.title}
      />
      <ChooseImg
        positions={["calc(55vh/2)", "0", "10px", "75%"]}
        src={slime.file.url}
        width="40%"
        pink
        alt={slime.title}
      />
      <ChooseImg
        positions={["0%", "00%", "0%", "0%"]}
        src={saturn.file.url}
        saturn
        alt={saturn.title}
      />
      <ChooseImg
        positions={["0%", "00%", "0%", "0%"]}
        src={satellite.file.url}
        satellite
        alt={satellite.title}
      />
      <AstronautTwo fluid={astronaut.fluid} alt={astronaut.title} />
    </AstroDiv>
  )
}

const AstroDiv = styled.div`
  @media (max-width: 640px) {
    /* min-height: 500px; */
    height: 50vh;
    /* overflow-x: hidden; */
  }

  @media (min-width: 641px) {
    margin-top: 15%;
    margin-bottom: 0;
    min-height: 400px;
  }

  @media (min-width: 1024px) {
    min-height: 600px;

    margin-top: 150px;
    height: auto;
    /* overflow-x: hidden; */
  }
`

const AstronautTwo = styled(Img)`
  display: block;
  position: absolute;
  width: 80%;
  animation: astronaut 35s linear infinite;
  transition: all 300ms;

  @media (max-width: 640px) {
    top: 25%;
    width: 45%;
    left: 110px;
  }

  @media (min-width: 641px) {
    left: 60px;
    width: 80%;
    max-width: 310px;
  }

  @media (min-width: 1024px) {
    width: 47%;
    margin-top: 21%;
    left: 170px;
  }

  @keyframes astronaut {
    0% {
      transform: translateY(0px) rotate(0deg) translateX(0px);
    }
    50% {
      transform: translateY(65px) rotate(-5deg) translateX(-20px);
    }
    100% {
      transform: translateY(0px) rotate(0deg) translateX(0px);
    }
  }
`
const ChooseImg = styled.img`
  display: block;
  position: absolute;
  width: 7%;
  transition: all 300ms;
  margin: ${props => props.margin};
  top: ${props => props.positions[0]};
  left: ${props => props.positions[3]};
  right: ${props => props.positions[1]};
  bottom: ${props => props.positions[2]};
  transform: ${props => props.trans};
  display: inline-block;

  @media (max-width: 960px) {
    width: 11%;
    width: ${props => props.width};
  }
  ${({ black }) =>
    black &&
    `
    @media (max-width: 640px) {
opacity:1;
}

@media (min-width: 641px) {
  margin-top:-32%;
  width:13%;
  opacity:0;
}

@media (min-width: 1024px) {
  margin-top:-2%;
    width:5%;
}
  box-shadow:0px 0px 20px 12px rgba(0, 0, 0, 0.3);
   border-radius: 50px;
 animation: black 10s linear infinite; 
 opacity:0.95;
 @keyframes black {
  0% {
    transform: rotate(360deg);
  }}

 `}

  ${({ neptune }) =>
    neptune &&
    `
@media (max-width: 640px) {
}

@media (min-width: 641px) {
    width:15%;
}

@media (min-width: 1024px) {
  width:15%;
}
`}
 ${({ pluto }) =>
    pluto &&
    `
@media (max-width: 640px) {
}

@media (min-width: 641px) {
    margin:60% 0% 0% 45%;
  width: 25%;
}

@media (min-width: 1024px) {
  margin:50% 0% 0% 35%;
  width: 25%;

 
}
`}
 ${({ jupiter }) =>
    jupiter &&
    `
@media (max-width: 640px) {
}

@media (min-width: 641px) {
    width:23%;
      margin: -55% 0% 0% 5%;
      margin: -200px 0 0 0px;
}

@media (min-width: 1024px) {
  width:25%;
     margin: -28% 0% 0% 0%;
}
`}
 ${({ pink }) =>
    pink &&
    `
@media (max-width: 640px) {
}

@media (min-width: 641px) {
     width:35%;
  margin: -75% 0% 0% -93%;
}

@media (min-width: 1024px) {
   width:40%;
  margin: -200px 0% 0% -93%;
}
`}
 ${({ saturn }) =>
    saturn &&
    `
@media (max-width: 640px) {
  opacity:0;
}

@media (min-width: 641px) {
  opacity:1;
   width:7%;
  margin: -25% 0% 0% 20%;
}

@media (min-width: 1024px) {
  opacity:1;
   width:7%;
  margin: -10% 0% 0% 20%;
}
`}
 ${({ satellite }) =>
    satellite &&
    `
@media (max-width: 640px) {
  opacity:0;
}

@media (min-width: 641px) {
  // opacity:1;
  //  width:10%;
  // margin: 110% 0% 0% 40%;
  opacity:0;
}

@media (min-width: 1024px) {
  opacity:1;
   width:5%;
  margin: 85% 0% 0% 30%;
}
  animation: satellite 60s linear infinite;
@keyframes satellite {
    0% {
      transform: translateY(0px) rotate(0deg) translateX(0px);
    }
    50% {
      transform: translateY(50px) rotate(180deg) translateX(-20px);
    }
    100% {
      transform: translateY(0px) rotate(360deg) translateX(0px);
    }
  }

`}
`

export default ChooseAstronaut
