import React, { useContext } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import Propositions from "./Propositions"
import { Row } from "../../globalStyles"
import { FullContainerLocal, StarsSix, Planets } from "./HomeValuePropsStyle"
import { Parallax } from "react-scroll-parallax"

function HomeValueProps({ data }) {
  const { isDarkMode } = useContext(ThemeContext)

  let items = data.allContentfulValuePropsMini.nodes

  let mainValue = data.allContentfulValuePropsMain.nodes

  const valueProps = items.map((x, i) => (
    <Propositions
      key={i}
      rocket={x.isRocket}
      dark={isDarkMode}
      rotate={x.rotate}
      icon
      img={x.miniSvg}
      small={x.smallMobileUnderline}
      miniTitle={x.miniTitle}
      miniPara={x.miniPara}
      cls="col-11 col-md-4 col-lg-4 "
    />
  ))

  // const { parallaxController } = useController()

  // useLayoutEffect(() => {
  //   const handler = () => parallaxController.update()
  //   window.addEventListener("load", handler)
  //   return () => window.removeEventListener("load", handler)
  // }, [parallaxController])

  return (
    <FullContainerLocal
      id="ertekeink"
      dark={isDarkMode}
      className="container-fluid"
    >
      <Parallax
        x={[0, 10]}
        // styleOuter={{ transform: `translateX(40px)` }}
        tagOuter="figure"
      >
        <Planets
          dark
          alt={mainValue[0].mainSvg[0].title}
          src={mainValue[0].mainSvg[0].file.url}
        />
      </Parallax>
      <Parallax x={[10, -10]} tagOuter="figure">
        <Planets
          purple
          alt={mainValue[0].mainSvg[1].title}
          src={mainValue[0].mainSvg[1].file.url}
        />
      </Parallax>
      {!isDarkMode && <StarsSix />}

      <Row className="row justify-content-center  h-50 text-center align-items-center">
        <Propositions
          rocket={false}
          dark={isDarkMode}
          top
          miniPara={mainValue[0].mainPara.mainPara}
          title={mainValue[0].mainTitle}
          cls="col-12 col-md-8 col-lg-6 mb-5 align-self-center"
        />

        <div className="row mx-auto align-content-start justify-content-center text-center">
          {valueProps}
        </div>
      </Row>
    </FullContainerLocal>
  )
}

export default HomeValueProps
