import React, { useState } from "react"
import styled from "styled-components"
import { H4, H3, LandingButton, buttonBckg } from "../../globalStyles"
// import ReCAPTCHA from "react-google-recaptcha"
// import { navigate } from "gatsby-link"
function ContactForm(props) {
  const {
    email,
    name,
    message,
    button,
    dark,
    landing,
    textPlace,
    mailPlace,
    nevPlace,
    webmindEmail,
    webmindTel,
    adatkezeles,
  } = props

  const [form, setState] = useState({
    name: "",
    email: "",
    text: "",
    gdpr: false,
  })

  const handleChange = e => {
    if (e.target.name !== "gdpr") {
      setState({ ...form, [e.target.name]: e.target.value })
    } else if (e.target.name === "gdpr") {
      setState({
        ...form,
        [e.target.name]: e.target.checked,
      })
    }
  }
  // function encode(data) {
  //   return Object.keys(data)
  //     .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
  //     .join("&")
  // }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   const form = e.target
  //   fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({
  //       "form-name": form.getAttribute("name"),
  //       ...form,
  //     }),
  //   })
  //     .then(() => navigate(form.getAttribute("action")))
  //     .catch(error => alert(error))
  // }

  return (
    <Col
      dark={dark}
      landing={landing}
      className="col-12 col-lg-11 col-md-10 align-content-center justify-content-center m-auto  "
    >
      <Telephone
        landing={landing}
        className="col-9 mb-3 mt-2 col-md-5 mx-auto"
        dark={dark}
      >
        <ClickableContact
          bold={true}
          dark={landing ? false : dark}
          href="tel:+36705588655"
          className="col-sm-10 col-md-10 col-lg-11 mt-2 mr-auto ml-auto "
        >
          {/* TELEFON: */}
          {webmindTel}
        </ClickableContact>
      </Telephone>
      <Telephone
        landing={landing}
        className="col-10 mb-4 col-md-7 mx-auto"
        dark={dark}
      >
        <ClickableContact
          href="mailto:hey@webmind.hu"
          bold={true}
          dark={landing ? false : dark}
          className="mt-2 mr-auto ml-auto "
        >
          {/* EMAIL: */}
          {webmindEmail}
        </ClickableContact>
      </Telephone>
      <form
        name="contact"
        method="POST"
        autoComplete="off"
        // autoComplete="off"
        action="https://formspree.io/f/mleoaavq"
        // data-netlify="true"
        // data-netlify-honeypot="bot-field"
        // onSubmit={handleSubmit}
        // data-netlify-recaptcha="true"
        // action="/"
      >
        {/* <input type="hidden" name="form-name" value="contact" /> */}
        <input type="hidden" name="_next" value="/" />
        <div className="row w-100  m-auto justify-content-between mr-auto ">
          {/* LABELS */}
          <div className="row w-100   mt-2 justify-content-around  mx-auto ">
            <Label htmlFor="name">
              <H3 dark={landing ? false : dark}>{name}:</H3>
            </Label>

            <Label htmlFor="email">
              <H3 dark={landing ? false : dark}> {email}:</H3>
            </Label>
          </div>
          {/* LABELS */}

          <Input
            dark={dark}
            className=" mb-3 "
            type="name"
            name="name"
            id="name"
            // placeholder={name.toLowerCase()}
            placeholder={nevPlace}
            required
            onChange={handleChange}
            value={form.name}
            landing={landing}
          />

          <Input
            dark={dark}
            className="  mb-3  "
            type="email"
            id="email"
            name="email"
            // placeholder={email.toLowerCase()}
            placeholder={mailPlace}
            required
            onChange={handleChange}
            value={form.email}
            landing={landing}
          />
        </div>
        <div className="mt-3">
          <Label htmlFor="text">
            <H3 dark={landing ? false : dark}>{message}:</H3>
          </Label>
          <TextArea
            dark={dark}
            className="col-12"
            name="text"
            id="text"
            // placeholder={message}
            placeholder={textPlace}
            onChange={handleChange}
            value={form.subject}
            landing={landing}
          ></TextArea>
        </div>
        <Label dark={dark} htmlFor="gdpr">
          <H4
            bold={true}
            dark={landing ? false : dark}
            className="col-sm-10 col-md-10 col-lg-11 mt-2 mr-auto ml-auto "
            style={{ fontStyle: "italic" }}
          >
            <input
              onChange={handleChange}
              name="gdpr"
              type="checkbox"
              required
              checked={form.gdpr}
            />{" "}
            {adatkezeles}
          </H4>{" "}
        </Label>
        {/* <ReCAPTCHA
          className="mx-auto"
          sitekey=""
        /> */}
        <LandingButton dark={dark} type="submit" value="SUBMIT">
          <H4>{button}</H4>
        </LandingButton>
      </form>
    </Col>
  )
}

export const Col = styled.div`
  /* background-color: pink; */
  transition: all 300ms;
  padding: 2.5% 0 2.5% 0;
  border-radius: 10px;
  margin: 5% auto auto auto !important;

  ${({ dark, landing }) =>
    !landing &&
    `border: 1px solid ${!dark ? buttonBckg.light : "transparent"};`};

  @media (max-width: 960px) {
    margin-top: ${({ sm }) => sm && `10%`};
    font-size: ${({ font }) => font && `.9rem`};
    height: auto;
    width: auto;
    color: black !important;
    margin-left: auto;
    margin-right: auto;
  }
`
export const Input = styled.input`
  transition: all 300ms;
  border-radius: 10px;
  color: black;
  padding-left: 5px;
  margin-left: auto;
  margin-right: auto;
  border: none;

  @media (max-width: 640px) {
    height: 25px;
    &:focus {
      border-radius: 20px;
    }
    width: 49%;
    height: 25px;
    padding-left: 8px;
    font-size: 0.9rem;
  }

  @media (min-width: 641px) {
    width: 48%;
    height: 45px;

    padding-left: 15px;

    font-size: 1.2rem;
  }

  @media (min-width: 1024px) {
    width: 40%;
    height: 30px;
  }

  background: ${({ dark }) =>
    !dark ? `rgba(55, 59, 50,0.3)` : `rgb(240, 240, 250);`};

  color: ${({ dark }) => (!dark ? `white` : `rgb(80,80,80);`)};
  border: 1px solid
    ${({ dark }) => (!dark ? `rgba(55, 59, 50,1)` : `rgba(240, 240, 250,0.5);`)};

  ${({ landing, dark }) =>
    landing &&
    ` 
    background: ${!dark ? `rgba(55, 59, 50,0.3)` : `rgba(240,240,250,.4);`};
    background: transparent;
    color:white;`}

  &::placeholder {
    color: ${({ dark }) => (!dark ? `rgba(255,255,255,.8)` : `default`)};
    ${({ dark, landing }) =>
      landing &&
      `color: ${!dark ? `rgba(255,255,255,.8)` : `rgba(255,255,255,.7);`}`};
  }
`
export const TextArea = styled.textarea`
  margin-left: auto;
  margin-right: auto;
  resize: none;
  color: black;
  border-radius: 10px;
  transition: all 300ms;
  transition-timing-function: ease-out;
  border: none;
  /* border: 1px solid rgb(240, 240, 250); */
  @media (max-width: 640px) {
    height: 70px;
    width: 95%;
    &:focus {
    }
    margin-bottom: 15px;
    padding-left: 8px;
    padding-top: 5px;
  }

  @media (min-width: 641px) {
    height: 80px;

    border-width: 1px;
    width: 100%;
    height: 90px;
    padding-left: 15px;
    font-size: 1.2rem;
  }

  @media (min-width: 1024px) {
    height: 80px;

    border-width: 1px;
    width: 90%;
    height: 120px;

    padding-left: 15px;
    margin-top: 1%;
  }

  background: ${({ dark }) =>
    !dark ? `rgba(55, 59, 50,0.3)` : `rgb(240, 240, 250);`};
  border: 1px solid
    ${({ dark }) => (!dark ? `rgba(55, 59, 50,1)` : `rgba(240, 240, 250,0.5);`)};

  color: ${({ dark }) => (!dark ? `white` : `rgb(80,80,80);`)};

  /* ${({ landing, dark }) =>
    landing &&
    `
  background: ${
    !dark ? `rgba(55, 59, 50,0.3)` : `rgba(240,240,250,.4);`
  }; color:white;
  `} */
  ${({ landing, dark }) =>
    landing &&
    `
  background: transparent; color:white;

  `}

  &::placeholder {
    color: ${({ dark }) => (!dark ? `rgba(255,255,255,.8)` : `default`)};

    ${({ dark, landing }) =>
      landing &&
      `color: ${!dark ? `rgba(255,255,255,.8)` : `rgba(255,255,255,.7);`}`};
  }
`

export const Label = styled.label`
  margin-left: auto;
  margin-right: auto;

  /* border: 1px solid red; */

  @media (min-width: 641px) {
    width: 48%;
    height: auto;

    /* padding-left: 25px; */
  }

  @media (min-width: 1024px) {
    width: 25%;
    height: 30px;
  }
`
export const Telephone = styled.div`
  transition: all 300ms;
  height: auto;
  width: auto;
  background: ${({ dark }) =>
    !dark ? `rgba(55, 59, 50,0.3)` : `rgb(240, 240, 250)`};

  border: 1px solid
    ${({ dark }) => (!dark ? `rgba(55, 59, 50,1)` : `rgba(240, 240, 250,0.5);`)};
  /* 
  ${({ landing, dark }) =>
    landing &&
    ` background: ${
      !dark ? `rgba(55, 59, 50,0.3)` : `rgba(240,240,250,.4);`
    };`} */
  ${({ landing, dark }) => landing && ` background:transparent;`}

  border-radius: 10px;
`
export default ContactForm

export const ClickableContact = styled.a`
  color: ${({ dark }) => (dark ? "#505050" : "white")};
  text-decoration: none;
  @media (max-width: 640px) {
    font-size: 1.05rem;
  }

  @media (min-width: 641px) {
    font-size: 1.2rem;
  }

  /* ${bold => (bold ? `font-weight: 500;` : `font-weight: 300;`)} */

  font-weight: ${({ bold }) => (bold === true ? 500 : 300)};
`
