import React, { useEffect } from "react"
import styled from "styled-components"
import { FullContainer } from "../../globalStyles"
import Matter from "matter-js"
// const Matter = lazy(() => import("matter-js"))

function MatterJs(props) {
  const { smartphone, tablet, laptop, desktop, width, height } = props

  const debounce = (func, wait, immediate) => {
    let timeout

    return function executedFunction() {
      let context = this
      let args = arguments

      let later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }

      let callNow = immediate && !timeout

      clearTimeout(timeout)

      timeout = setTimeout(later, wait)

      if (callNow) func.apply(context, args)
    }
  }

  useEffect(() => {
    const theWidth = width
    const theHeight = height

    let engine = Matter.Engine.create()
    let render = Matter.Render.create({
      element: document.querySelector("#render"),
      engine: engine,
      options: {
        width: theWidth,
        height: theHeight,
        background: "transparent",
        // background: "red",
        showAngleIndicator: false,
        wireframes: false,
        showVelocity: false,
      },
    })

    let mouse = Matter.Mouse.create(render.canvas, {
      enabledEvents: {
        mousewheel: false,
      },
    })
    let mouseCoonstraint = Matter.MouseConstraint.create(engine, {
      mouse: mouse,

      constraint: { stiffness: 0.8, render: { visible: false } },
    })

    render.mouse = mouse
    // console.log(engine)
    mouse.element.removeEventListener("mousewheel", mouse.mousewheel)
    mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel)
    if (width < 640) {
      mouse.element.removeEventListener("touchmove", mouse.mousemove)
      mouse.element.removeEventListener("touchstart", mouse.mousedown)
      mouse.element.removeEventListener("touchend", mouse.mouseup)
    }

    let color = "transparent"
    Matter.World.add(engine.world, [
      //WELCOME
      // Matter.Bodies.rectangle(277, 300, 462, 450, {
      //   isStatic: true,
      //   frictionStatic: 10,
      //   restitution: 1,
      //   render: {
      //     fillStyle: "red",
      //   },
      // }),

      // FENT
      Matter.Bodies.rectangle(theWidth / 2, -10, theWidth, 50, {
        isStatic: true,
        frictionStatic: 10,
        restitution: 1,
        render: {
          fillStyle: color,
        },
      }),

      // LENT
      Matter.Bodies.rectangle(theWidth / 2, theHeight - 30, theWidth, 50, {
        isStatic: true,
        frictionStatic: 10,
        restitution: 1,
        render: {
          fillStyle: color,
        },
      }),

      //BALRA
      Matter.Bodies.rectangle(-10, theHeight / 2, 50, theHeight, {
        isStatic: true,
        frictionStatic: 10,
        restitution: 1,
        render: {
          fillStyle: color,
          //   strokeStyle: "pink",
          //   lineWidth: 3,
        },
      }),

      //JOBBRA
      Matter.Bodies.rectangle(theWidth - 10, theHeight / 2, 50, theHeight, {
        isStatic: true,
        frictionStatic: 10,
        restitution: 1,
        render: {
          fillStyle: color,
        },
      }),
    ])

    let rand1 = Math.floor(Math.random() * (theWidth / 2) + theWidth / 2.4)
    let rand2 = Math.floor(Math.random() * (theWidth / 2) + theWidth / 2.3)
    let rand3 = Math.floor(Math.random() * (theWidth / 2) + theWidth / 2.2)
    let rand4 = Math.floor(Math.random() * (theWidth / 2) + theWidth / 2.1)
    ///////
    let heightRand1 = Math.floor(
      Math.random() * (theHeight / 3) + theHeight / 3.1
    )
    let heightRand2 = Math.floor(
      Math.random() * (theHeight / 3) + theHeight / 3.2
    )
    let heightRand3 = Math.floor(
      Math.random() * (theHeight / 3) + theHeight / 2.6
    )
    let heightRand4 = Math.floor(
      Math.random() * (theHeight / 3) + theHeight / 2.9
    )
    let boxA = Matter.Bodies.rectangle(rand1, heightRand1, 30, 70, {
      frictionAir: 0.09,
      angle: 120,
      motion: 12,
      density: 0.1,
      restitution: 1,
      render: {
        sprite: {
          texture: smartphone,
          xScale: 0.1,
          yScale: 0.1,
        },
      },
    })

    let boxB = Matter.Bodies.rectangle(rand2, heightRand2, 80, 80, {
      frictionAir: 0.09,
      angle: 70,
      motion: 12,
      density: 0.1,
      restitution: 1,
      render: {
        sprite: {
          texture: laptop,
          xScale: 0.155,
          yScale: 0.155,
        },
      },
    })
    let boxC = Matter.Bodies.rectangle(rand3, heightRand3, 30, 80, {
      frictionAir: 0.09,
      angle: 240,
      motion: 12,
      density: 0.1,
      restitution: 1,
      render: {
        sprite: {
          texture: tablet,
          xScale: 0.1,
          yScale: 0.1,
        },
      },
    })
    let boxD = Matter.Bodies.rectangle(rand4, heightRand4, 70, 70, {
      frictionAir: 0.09,
      angle: 170,
      motion: 12,
      density: 0.1,
      restitution: 1,
      render: {
        sprite: {
          texture: desktop,
          xScale: 0.165,
          yScale: 0.165,
        },
      },
    })
    // engine.world.gravity.y = -0.009
    engine.world.gravity.y = -0.02
    engine.world.gravity.scale = 0.0005
    engine.world.gravity.x = 0
    engine.timing.timeScale = 0.7

    let arr = [boxA, boxB, boxC, boxD]

    arr.forEach(elem => {
      let preForce = Math.random() < 0.5 ? 15 : -15
      let force = Math.random() * preForce
      let deltaVector = Matter.Vector.sub(mouse.position, elem.position)
      let normalizedDelta = Matter.Vector.normalise(deltaVector)
      let forceVector = Matter.Vector.mult(normalizedDelta, force)

      return Matter.Body.applyForce(elem, elem.position, forceVector)
    })

    // an example of using collisionEnd event on an engine
    Matter.Events.on(engine, "collisionStart", function (event) {
      let pairs = event.pairs

      for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i]
        pair.bodyA.render.opacity = 0.7
        pair.bodyB.render.opacity = 0.7
      }
    })
    Matter.Events.on(engine, "collisionEnd", function (event) {
      let pairs = event.pairs
      //   console.log("Evento: ", event)

      //   console.log("Pair no visible: ", pairs)
      //   console.log("Pair visible: ", pairs[0])

      // change object colours to show those starting a collision
      for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i]
        pair.bodyA.render.opacity = 1
        pair.bodyB.render.opacity = 1
      }
    })

    // console.log(render)
    // console.log(boxA)
    Matter.World.add(engine.world, [boxA, boxB, boxC, boxD, mouseCoonstraint])
    Matter.Engine.run(engine)
    Matter.Render.run(render)

    // console.log(render)
    ////////////////////////////////////////////////
    window.addEventListener(
      "resize",
      debounce(() => {
        // console.log(window.innerWidth)
        Matter.Engine.clear(engine)
        Matter.World.clear(engine.world, false)
        Matter.Render.stop(render)
        let theWidth = window.innerWidth
        let theHeight = window.innerHeight
        // console.log(theWidth)

        Matter.World.clear(engine.world)
        Matter.Engine.clear(engine)
        Matter.Render.stop(render)
        render.canvas.remove()

        setTimeout(() => {
          render = Matter.Render.create({
            element: document.querySelector("#render"),
            engine: engine,
            options: {
              width: theWidth,
              height: theHeight,
              // background: "red",
              background: "transparent",
              showAngleIndicator: false,
              wireframes: false,
            },
          })

          mouse = Matter.Mouse.create(render.canvas, {
            enabledEvents: {
              mousewheel: false,
            },
          })
          mouseCoonstraint = Matter.MouseConstraint.create(engine, {
            mouse: mouse,

            constraint: { stiffness: 0.8, render: { visible: true } },
          })
          render.mouse = mouse
          render.options.width = window.innerWidth
          render.options.height = window.innerHeight
          // console.log(render)

          mouse.element.removeEventListener("mousewheel", mouse.mousewheel)
          mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel)
          Matter.World.add(engine.world, [
            // FENT
            Matter.Bodies.rectangle(theWidth / 2, -10, theWidth, 50, {
              isStatic: true,
              frictionStatic: 10,
              restitution: 1,
              render: {
                fillStyle: color,
              },
            }),

            // LENT
            Matter.Bodies.rectangle(
              theWidth / 2,
              theHeight - 70,
              theWidth,
              50,
              {
                isStatic: true,
                frictionStatic: 10,
                restitution: 1,
                render: {
                  fillStyle: color,
                },
              }
            ),

            //BALRA
            Matter.Bodies.rectangle(-10, theHeight / 2, 50, theHeight, {
              isStatic: true,
              frictionStatic: 10,
              restitution: 1,
              render: {
                fillStyle: color,
                //   strokeStyle: "pink",
                //   lineWidth: 3,
              },
            }),

            //JOBBRA
            Matter.Bodies.rectangle(
              theWidth - 10,
              theHeight / 2,
              50,
              theHeight,
              {
                isStatic: true,
                frictionStatic: 10,
                restitution: 1,
                render: {
                  fillStyle: color,
                },
              }
            ),
          ])
          let rand1 = Math.floor(
            Math.random() * (theWidth / 2) + theWidth / 2 + 50
          )
          let rand2 = Math.floor(
            Math.random() * (theWidth / 2) + theWidth / 2 + 100
          )
          let rand3 = Math.floor(
            Math.random() * (theWidth / 2) + theWidth / 2 - 40
          )
          let rand4 = Math.floor(
            Math.random() * (theWidth / 2) + theWidth / 2 - 90
          )

          let heightRand1 = Math.floor(
            Math.random() * (theHeight / 3) + theHeight / 3.1
          )
          let heightRand2 = Math.floor(
            Math.random() * (theHeight / 3) + theHeight / 3.2
          )
          let heightRand3 = Math.floor(
            Math.random() * (theHeight / 3) + theHeight / 2.6
          )
          let heightRand4 = Math.floor(
            Math.random() * (theHeight / 3) + theHeight / 2.9
          )
          let boxA = Matter.Bodies.rectangle(rand1, heightRand1, 30, 70, {
            frictionAir: 0.09,
            angle: 120,
            motion: 12,
            density: 0.1,
            restitution: 1,
            render: {
              sprite: {
                texture: smartphone,
                xScale: 0.1,
                yScale: 0.1,
              },
            },
          })

          let boxB = Matter.Bodies.rectangle(rand2, heightRand2, 80, 80, {
            frictionAir: 0.09,
            angle: 70,
            motion: 12,
            density: 0.1,
            restitution: 1,
            render: {
              sprite: {
                texture: laptop,
                xScale: 0.18,
                yScale: 0.18,
              },
            },
          })
          let boxC = Matter.Bodies.rectangle(rand3, heightRand3, 30, 80, {
            frictionAir: 0.09,
            angle: 240,
            motion: 12,
            density: 0.1,
            restitution: 1,
            render: {
              sprite: {
                texture: tablet,
                xScale: 0.1,
                yScale: 0.1,
              },
            },
          })
          let boxD = Matter.Bodies.rectangle(rand4, heightRand4, 70, 70, {
            frictionAir: 0.09,
            angle: 170,
            motion: 12,
            density: 0.1,
            restitution: 1,
            render: {
              sprite: {
                texture: desktop,
                xScale: 0.165,
                yScale: 0.165,
              },
            },
          })
          Matter.World.add(engine.world, [
            boxA,
            boxB,
            boxC,
            boxD,
            mouseCoonstraint,
          ])
          Matter.Render.run(render)
        }, 300)
      }, 1000)
    )
    ////////////////////////////////////////////////

    return () => {}
  }, [smartphone, tablet, laptop, desktop, width, height])

  return <FullContainerLocal id="render"></FullContainerLocal>
}

export const FullContainerLocal = styled(FullContainer)`
  background: transparent;
  position: absolute;
  height: auto;
  top: 0;
  z-index: 1;
  overflow: hidden;
  transition: all 600ms;
  ${FullContainer};
`

export default MatterJs
