import React from "react"
import styled from "styled-components"

import { LandingButton, H1, H3, H4 } from "../../globalStyles"
import scrollToTarget from "../../components/helpers/scrollToView"
function Welcome(props) {
  const { isDarkMode } = props

  const { buttonTxt, header, para } = props

  let isDark = isDarkMode ? "light" : "dark"

  return (
    <JumboBoxContainer
      id="welcome"
      className=" align-content-center text-left ml-auto mr-auto col-11 col-sm-8 col-md-9 col-lg-6"
    >
      <JumboMainTextBox>
        {/* <H1>Készen állsz a vállalkozásod digitalizációjára?</H1> */}
        {/* <H1 landing>{header}</H1> */}
        <H1 landing>{header}</H1>
        <JumboSecondaryTextBox>
          <H3>{para}</H3>
        </JumboSecondaryTextBox>
      </JumboMainTextBox>
      <LandingButton id="tovabb-landing" dark={isDark} primary={isDark}>
        <H4
          id="tovabb-landing-h4"
          onClick={() => {
            scrollToTarget(document.querySelector("#ertekeink"))
            // window.gtag("event", "click", {
            //   event_category: "navigation",
            //   event_label: 'inner-navigation',
            //   transport_type: "scroll-to",
            //   // event_callback: function () {
            //   //   document.location = url
            //   // },
            // })
          }}
        >
          {buttonTxt}
        </H4>
      </LandingButton>
    </JumboBoxContainer>
  )
}

const JumboBoxContainer = styled.div`
  display: block;
  pointer-events: none;
  position: relative;
  z-index: 111;
  /* background: red; */
  @media (max-width: 640px) {
    width: 100%;
    /* background: green; */
    height: 50%;
    margin: 0% 0% 0% 5%;
  }

  @media (min-width: 641px) {
    margin: 10% 0% 0% 10%;
  }

  @media (min-width: 1024px) {
    margin: 5% 0% 0% 5%;
  }

  @media screen and (min-width: 1280px) {
    width: 40%;
    max-width: 1600px;
    /* background: yellow; */
    height: 40%;
    max-height: 1500px;
  }
`

const JumboMainTextBox = styled.div`
  width: auto;
  /* background-color: pink; */
  display: inline-block;
  margin: 5% 0 0 0;

  @media screen and (max-width: 960px) {
    /* margin: 5% 0 0 0; */
  }
`

const JumboSecondaryTextBox = styled.div`
  width: 85%;
  /* background-color: magenta; */
  display: inline-block;
  margin: 2.5% 0 0 0;

  @media screen and (max-width: 960px) {
    margin-top: 10%;
    width: 100%;
    max-width: 100%;
    /* font-size: 0.75rem; */
  }
`

export default Welcome
