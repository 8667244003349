// import Layout from "../Layout"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
function SuccessAstronaut(props) {
  // const { satellite, stripe, astronaut, orange, dark, big1, rocks } = props

  // console.log(props)
  return (
    <SuccessDiv className="col-12 justify-content-center text-center align-content-center mr-auto  col-md-12 col-lg-6 ">
      <ChooseImg
        loading="lazy"
        positions={["0px", "0px", "0px", "0px"]}
        // src={rocks.file.url}
        trans="rotate(270deg)"
        rocks
        width="5%"
        // alt={rocks.title}
        src={props[5].file.url}
        alt={props[5].title}
      />

      <Satellite>
        <SatImage
          black
          // alt={satellite.title} src={satellite.file.url}
          alt={props[0].title}
          src={props[0].file.url}
          loading="lazy"
        />
        <Radio />
      </Satellite>
      <ChooseImg
        width={"16%"}
        positions={["50%", "0px", "10px", "10px"]}
        // src={orange.file.url}
        // alt={orange.title}
        src={props[3].file.url}
        alt={props[3].title}
        loading="lazy"
        orange
      />

      <ChooseImg
        positions={["25%", "0px", "10px", "79%"]}
        // src={dark.file.url}
        // alt={dark.title}
        src={props[4].file.url}
        alt={props[4].title}
        width="20%"
        loading="lazy"
      />
      <ChooseImg
        positions={["calc(5vh/2)", "0", "10px", "-15%"]}
        // src={stripe.file.url}
        // alt={stripe.title}
        src={props[1].file.url}
        alt={props[1].title}
        width="40%"
        stripe
        loading="lazy"
      />
      <ChooseImg
        positions={["calc(70vh/2)", "0", "10px", "-55%"]}
        // src={big1.file.url}
        // alt={big1.title}
        // src={props[5].file.url}
        // alt={props[5].title}
        src={props[6].file.url}
        alt={props[6].title}
        trans="rotate(-30deg)"
        width="220%"
        big1
        loading="lazy"
      />

      <AstronautThree
        // alt={astronaut.title}
        // fluid={astronaut.fluid}
        alt={props[2].title}
        fluid={props[2].fluid}
      />
    </SuccessDiv>
  )
}

const SuccessDiv = styled.div`
  display: relative;
  /* background: red; */

  @media (max-width: 640px) {
    height: auto;
    min-height: 270px;
    width: 100vw;
  }

  @media (min-width: 641px) {
    height: auto;
    width: 100vw;
    min-height: 500px;

    margin-top: 90px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 100%;
    margin-top: 150px;
  }
`

const AstronautThree = styled(Img)`
  display: block;
  position: absolute;
  width: 80%;
  animation: astronaut 25s linear infinite;

  transition: all 300ms;
  /* background: pink; */

  @media (max-width: 640px) {
    top: 30%;

    width: 60%;
    /* min-width: 200px; */
    max-width: 300px;
    left: 40px;
    left: 22%;
    height: auto;
  }

  @media (min-width: 641px) {
    width: 50%;
    max-width: 350px;
    top: 100px;
    left: 180px;
  }

  @media (min-width: 1024px) {
    /* top: 38%; */
    top: 220px;
    /* top: 40%; */

    width: 330px;
    left: 180px;
  }

  @keyframes astronaut {
    0% {
      transform: translateY(0px) rotate(-10deg) translateX(0px);
    }
    50% {
      transform: translateY(25px) rotate(0deg) translateX(-10px);
    }
    100% {
      transform: translateY(0px) rotate(-10deg) translateX(0px);
    }
  }
`

const Satellite = styled.div`
  display: block;
  /* background: green; */
  width: 100px;
  height: 100px;
  position: absolute;

  @media (max-width: 640px) {
    left: 38%;
    top: 2%;
    width: 80px;
    height: 80px;
  }

  @media (min-width: 641px) {
    left: 65%;
    top: -5%;
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1024px) {
    left: 38%;
    top: -20px;
    width: 100px;
    height: 100px;
  }

  animation: sati 120s linear infinite;

  @keyframes sati {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

const SatImage = styled.img`
  width: 40%;
  position: absolute;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 640px) {
    width: 30%;
    top: 47%;
    left: 47%;
  }

  @media (min-width: 641px) {
    top: 40%;
    left: 40%;
  }

  @media (min-width: 1024px) {
    top: 40%;
    left: 40%;
  }
`

const Radio = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  background-color: white;

  display: block;
  position: absolute;
  top: 30px;
  left: 30px;
  animation: radio 3s ease-out infinite;
  transition: 400ms;
  opacity: 1;

  @keyframes radio {
    0% {
      height: 20%;
      width: 20%;
      opacity: 0;
      position: relative;
    }

    50% {
      opacity: 0.1;
    }

    100% {
      height: 100%;
      width: 100%;
      margin-left: -40%;
      margin-top: -40%;
      opacity: 0;
    }
  }
`

const ChooseImg = styled.img`
  display: block;
  position: absolute;
  width: 7%;
  transition: all 300ms;
  margin: ${props => props.margin};
  top: ${props => props.positions[0]};
  left: ${props => props.positions[3]};
  right: ${props => props.positions[1]};
  bottom: ${props => props.positions[2]};
  transform: ${props => props.trans};
  display: inline-block;

  @media (max-width: 960px) {
    width: 11%;
    width: ${props => props.width};
  }
  ${({ black }) =>
    black &&
    `
 animation: black 90s linear infinite; 
 opacity:0.95;
@media (min-width: 641px) {
  

}

@media (min-width: 1024px) {
  width:5%;

}



 @keyframes black {
  0% {

    transform: rotate(0);
  }

    50% {
      
    transform: rotate(360deg);
  }
    100% {
      
    transform: rotate(0deg);
  }
 `}

  ${({ rocks }) =>
    rocks &&
    `

    @media (max-width: 640px) {

       animation: rocks 120s cubic-bezier(0.1, 0, 0, 0) infinite; 
        @keyframes rocks {
  0% {

    transform: translate(62px, 30px) scale(1);
  }
    50% {
  }
    100% {
      
       transform: translate(400px, 220px) scale(3) ;
  }};
}


@media (min-width: 641px) {
   animation: rocks 120s cubic-bezier(0.1, 0, 0, 0) infinite; 
  @keyframes rocks {
  0% {

    transform: translate(62px, 30px) scale(1);
  }
    50% {
  }
    100% {
      
       transform: translate(800px, 320px) scale(1.5) ;
  }};
}

@media (min-width: 1024px) {

   animation: rocks 300s cubic-bezier(0.1, 0, 0, 0) infinite; 
  width:3%;

 @keyframes rocks {
  0% {

    transform: translate(612px, -30px) scale(1);
  }

    100% {
      
       transform: translate(60px, 700px) scale(2) ;
  }
};

}



 opacity:0.95;
 
 `}
  ${({ big1 }) =>
    big1 &&
    `

    @media (max-width: 640px) {

// bottom:-10px;
// top:100%;
}

@media (min-width: 641px) {
  width:140%;
left:-18%;
bottom:0;
top:370px;
}

@media (min-width: 1024px) {
width:140%;
top:440px;
}
 
animation: big 470s linear infinite;
  @keyframes big {
    from {
      transform: rotate(0deg);
    }
    to {
      transform:  rotate(-360deg);
    }
  
  }



 `}

 //

 ${({ stripe }) =>
    stripe &&
    `

@media (min-width: 641px) {top:0px;
  left:-15%;
}

@media (min-width: 1024px) {
width:30%;
left:85%;
top:0px;

}
 
 `}


 ${({ orange }) =>
    orange &&
    `
@media (max-width: 640px) {
top:75%;

}
@media (min-width: 641px) {top:0px;
top:65%;
left:10%;
width:13%;
}
 
 `}
`

export default SuccessAstronaut
