import React, { useRef, useLayoutEffect, useState } from "react"

import { H1, H2, H3, buttonBckg } from "../../globalStyles"

import styled from "styled-components"

function Propositions(props) {
  const {
    cls,
    title,
    miniTitle,
    top,
    miniPara,
    small,
    rotate,
    img,
    dark,
    rocket,
  } = props
  //

  const [visibleRocket, setVisibleRocket] = useState(false)
  let rocketRef = useRef(null)

  //
  // let isVisible

  useLayoutEffect(() => {
    function theScroll() {
      try {
        // isVisible =
        // window.innerHeight / 1.6 >
        //   rocketRef.current.getBoundingClientRect().top &&
        // window.innerHeight / 2.6 <
        //   rocketRef.current.getBoundingClientRect().bottom

        return window.innerHeight / 1.6 >
          rocketRef.current.getBoundingClientRect().top &&
          window.innerHeight / 2.6 <
            rocketRef.current.getBoundingClientRect().bottom
          ? setVisibleRocket(true)
          : null
      } catch (err) {
        console.log(err)
      }
    }

    if (rocketRef.current !== null) {
      window.addEventListener("scroll", theScroll)
    }
    return () => {
      window.removeEventListener("scroll", theScroll)
    }
  }, [rocketRef])

  return (
    <ColMin small={small} top={top} className={cls}>
      <div className="mb-1">
        {top ? (
          <H1
            style={{
              color: dark ? buttonBckg.darkHover : "white",
            }}
          >
            {title}
          </H1>
        ) : (
          <BlobContainer className="my-auto mx-auto  ">
            <Blob dark={dark} rotate={rotate} />
            <FloatImg
              rocket={rocket}
              ref={rocket ? rocketRef : null}
              src={img.file.url}
              alt={img.title}
              visible={visibleRocket}
              loading="lazy"
            />
          </BlobContainer>
        )}
      </div>
      {!top ? (
        <H2
          dark={dark}
          values="true"
          className="mb-3 mt-2"
          style={{
            fontWeight: "bold",
            color: dark ? buttonBckg.dark : `white`,
            marginTop: props.top && window.innerWidth < 641 && "10%",
          }}
        >
          {miniTitle}
        </H2>
      ) : null}

      {/* <H3 style={{ color: dark ? "#505050" : "white" }}>{miniPara}</H3> */}
      {top ? (
        <H3
          className="mt-5 col-12"
          style={{ color: dark ? "#505050" : "white" }}
        >
          {miniPara}
        </H3>
      ) : (
        <H3 style={{ color: dark ? "#505050" : "white" }}>{miniPara}</H3>
      )}
    </ColMin>
  )
}

export default Propositions

export const ColMin = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 640px) {
    margin: 5% 0 5% 0;

    ${({ top }) => top && `margin-bottom:5%; margin-top:10%;`}
  }

  @media (min-width: 641px) {
    ${({ top }) => top && `margin-top:10%;`}
    margin-bottom:25px;
  }

  @media (min-width: 1024px) {
    ${({ top }) => top && `margin-top:3%; margin-bottom:3%;`}
    ${({ top }) => !top && `margin-top:2%; `}
  }
`

const BlobContainer = styled.div`
  margin: auto;
  padding: 13px;
  width: 13%;
  height: 100px;
  /* background: red; */
  width: auto;
  @media (max-width: 640px) {
    height: 70px;
  }
`

const Blob = styled.div`
  transition: all 300ms;
  position: absolute;
  border-radius: 64% 36% 42% 58% / 53% 30% 70% 47%;
  background: white;

  background: ${({ dark }) =>
    dark
      ? `linear-gradient(
    232deg,
    rgba(74, 67, 189, .8) 0%,
    rgba(38, 138, 221, .7) 39%,
    rgba(60, 94, 201, .5) 100%,
    rgba(0, 212, 255, .4) 100%
  )`
      : `rgba(61, 175, 245,0.5)`};

  background: ${({ dark }) =>
    dark
      ? `linear-gradient(
    232deg,
    rgba(74, 67, 189, .8) 0%,
    rgba(38, 138, 221, .7) 39%,
    rgba(60, 94, 201, .5) 100%,
    rgba(0, 212, 255, .4) 100%
  )`
      : `rgba(85, 89, 257,.5)`};

  width: 100px;
  height: 100px;
  left: 44%;
  top: 2%;
  transform: ${({ rotate }) => rotate};

  margin: auto;

  @media (max-width: 640px) {
    width: 70px;
    height: 70px;

    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 641px) {
    width: 75px;
    height: 70px;

    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 1024px) {
    width: 80px;
    height: 80px;
    width: 100px;
    height: 100px;
    /* top: 1%; */
    z-index: 1 !important;
    left: 50%;
    transform: translateX(-50%);
  }
  animation: blob 6s linear infinite;
  @keyframes blob {
    0% {
      border-radius: 23% 77% 74% 26% / 25% 68% 32% 75%;
    }
    50% {
      border-radius: 64% 36% 42% 58% / 53% 30% 70% 47%;
    }
    100% {
      border-radius: 23% 77% 74% 26% / 25% 68% 32% 75%;
    }
  }
`

const FloatImg = styled.img`
  position: relative;
  display: block;
  z-index: 10;
  transition: all 300ms;
  margin: auto;

  @media (max-width: 640px) {
    width: 40px;

    //
    /* top: -4px;
    left: 50%;
    transform: translateX(-50%); */
  }

  @media (min-width: 641px) {
    width: 45px;
  }

  @media (min-width: 1024px) {
    /* width: 50px; */
    width: 55px;
    margin-top: 7px;
  }

  ${({ visible }) =>
    visible &&
    `
@media (max-width: 640px){



animation: rocket 6s linear 0s 1 normal;
   @keyframes rocket {
    0% {
      transform:) rotate(0deg) 
    }
      10% {
      transform: rotate(-5deg);
    }

     15% {
      transform: rotate(-7deg) ;
    }

      20% {
      transform:rotate(-6deg);
    }
         25% {
      transform: rotate(5deg);
    }
      30% {
      transform:rotate(-7deg) ;
    }
      40% {
      transform:  rotate(0deg) ;
    }
      50% {
      transform: rotate(0deg) ;
    }
      60% {
  transform: rotate(-20deg);
    }
      70% {

            transform:  rotate(-20deg) ;
    }
    80% {
         transform: translateY(-100px) rotate(-125deg) translateX(90px) scale(1.05);
    }

  90% {
      transform: translate(-600px, -260px) rotate(-130deg) scale(.9);
    }
 100% {

      transform: translate(-1300px, -310px) rotate(-135deg) scale(.7);
    }
    100% {

      transform: translate(-1300px, -310px) rotate(-135deg) scale(.7);
    }
  }


}


    @media (min-width:641px){



animation: rocket 6s linear 0s normal;
   @keyframes rocket {
    0% {
      transform: translateY(0px) rotate(0deg) translateX(0px);
    }
      10% {
      transform: translateY(0px) rotate(-5deg) translateX(0px);
    }

     15% {
      transform: translateY(0px) rotate(-7deg) translateX(0px);
    }

      20% {
      transform: translateY(0px) rotate(-6deg) translateX(0px);
    }
         25% {
      transform: translateY(0px) rotate(5deg) translateX(0px);
    }
      30% {
      transform: translateY(0px) rotate(-7deg) translateX(0px);
    }
      40% {
      transform: translateY(0px) rotate(0deg) translateX(0px);
    }
      50% {
      transform: translateY(0px) rotate(0deg) translateX(0px);
    }
      60% {
  transform: translateY(0px) rotate(-20deg) translateX(0px);
    }
      70% {

            transform: translateY(0px) rotate(-20deg) translateX(0px);
    }
    80% {
         transform: translateY(-100px) rotate(-125deg) translateX(90px) scale(1.25);
    }

  90% {
      transform: translate(-600px, -260px) rotate(-130deg) scale(.9);
    }

    100% {

      transform: translate(-1300px, -310px) rotate(-135deg) scale(.7);
    }
  }
    }
  `};
`
