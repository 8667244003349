import React, { useRef, useEffect, useContext } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import useWindowSize from "../../hooks/resizeHook"

import { H1, buttonBckg, Row } from "../../globalStyles"
import styled from "styled-components"

import SaleItems from "./SaleItems"
import { Parallax } from "react-scroll-parallax"
// import { graphql, useStaticQuery } from "gatsby"
import { gsapScrollTrigger } from "../GSAP/gsap"
// import { gsapScrollTrigger, gsapDevicesScrollTrigger } from "../GSAP/gsap"
function HomeProductTypes({ data }) {
  const [width] = useWindowSize()
  const { isDarkMode } = useContext(ThemeContext)

  let mainItems = data.allContentfulProductTypesMain.nodes[0]

  let { svg, title } = mainItems

  let products = data.allContentfulProductTypes.nodes
  //
  const serviceRef = useRef(null)
  serviceRef.current = []
  const addToRefs = el => {
    if (el && !serviceRef.current.includes(el)) {
      serviceRef.current.push(el)
    }
  }

  useEffect(() => {
    if (serviceRef.current.length === products.length) {
      // -2 because the first two products are not mapped
      gsapScrollTrigger(serviceRef.current, 0, 100, width, 0, 0.2)
    }
    // console.log()

    return () => {}
  }, [products, width, serviceRef])
  ///////

  ////// DEVICES
  let itemRef = useRef(null)
  itemRef.current = []
  const addToRefImg = el => {
    if (el && !itemRef.current.includes(el)) {
      itemRef.current.push(el)
    }
  }
  // useEffect(() => {
  //   itemRef.current.forEach(x => {
  //     gsapDevicesScrollTrigger(x)
  //   })

  //   // console.log()

  //   return () => {}
  // }, [width, itemRef])
  ////// DEVICES

  let parallaxX = width < 641 ? [0, 0] : [0, 0]
  ///////

  let items = products.map((x, i) => {
    return (
      i >= 2 && (
        <SaleItems
          myRef={addToRefs}
          key={i}
          dark={isDarkMode}
          index={i}
          para={x.para.para}
          title={x.title}
          link={x.anchorTagLinks}
        />
      )
    )
  })
  return (
    <FullContainer dark={isDarkMode} className=" text-center   ">
      {/* {console.log(products)} */}
      {!isDarkMode && <StarsTwo />}

      <Parallax x={[parallaxX[0], -10]}>
        <Planets dark alt={svg[4].title} src={svg[4].file.url} />
      </Parallax>
      <Parallax x={[parallaxX[1], 10]}>
        <Planets purple alt={svg[5].title} src={svg[5].file.url} />
      </Parallax>
      <Row>
        <Col
          titleBool
          className="mt-5 col-11 text-center justify-content-center  col-md-10 col-lg-8 "
        >
          <H1
            style={{ color: isDarkMode ? buttonBckg.darkHover : "white" }}
            className="text-center"
          >
            {title}
          </H1>
        </Col>

        <div className="row w-100 justify-content-center  m-auto ">
          {width < 767 && (
            <Col
              // ref={itemRef}
              style={{ borderBottom: "4px solid rgba(60, 104, 201, .8)" }}
              className="col-10 col-sm-8 col-md-3 col-lg-3 mt-5 mr-0 ml-0 m-0 "
            >
              <ServiceImg
                ref={addToRefImg}
                desktop
                src={svg[0].file.url}
                alt={svg[0].title}
                loading="lazy"
              />
              <ServiceImg
                ref={addToRefImg}
                laptop
                src={svg[1].file.url}
                alt={svg[1].title}
                loading="lazy"
              />
              <ServiceImg
                ref={addToRefImg}
                tablet
                src={svg[2].file.url}
                alt={svg[2].title}
                loading="lazy"
              />
              <ServiceImg
                ref={addToRefImg}
                smartphone
                src={svg[3].file.url}
                alt={svg[3].title}
                loading="lazy"
              />
            </Col>
          )}
          <SaleItems
            myRef={addToRefs}
            key={1}
            dark={isDarkMode}
            para={products[0].para.para}
            title={products[0].title}
            link={products[0].anchorTagLinks}
          />
          {width >= 768 && (
            <Col
              // ref={itemRef}
              style={{ borderBottom: "4px solid rgba(60, 104, 201, .8)" }}
              className="col-10 col-md-4 col-lg-3 my-auto mr-0 ml-0 m-0"
            >
              <ServiceImg
                ref={addToRefImg}
                desktop
                src={svg[0].file.url}
                alt={svg[0].title}
              />
              <ServiceImg
                ref={addToRefImg}
                laptop
                src={svg[1].file.url}
                alt={svg[1].title}
              />
              <ServiceImg
                ref={addToRefImg}
                tablet
                src={svg[2].file.url}
                alt={svg[2].title}
              />
              <ServiceImg
                ref={addToRefImg}
                smartphone
                src={svg[3].file.url}
                alt={svg[3].title}
              />
            </Col>
          )}

          <SaleItems
            myRef={addToRefs}
            key={2}
            dark={isDarkMode}
            para={products[1].para.para}
            title={products[1].title}
            link={products[1].anchorTagLinks}
          />
        </div>
        <div className="row w-100 m-auto">{items}</div>
      </Row>
    </FullContainer>
  )
}

export default HomeProductTypes

export const FullContainer = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  transition: all 400ms;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  background: white;
  overflow: hidden;
  /* background: rgba(240, 240, 250); */
  /* padding-top: 15px; */
  padding-bottom: 15px;
  ////// KERDESES
  padding: 3% 0 5% 0;
  ////// KERDESES
  ${({ dark }) =>
    dark
      ? `
  
  background:rgba(240,240,250);
  
  `
      : ` 

background:         rgba(34,33,100,1) ;

background: rgba(24,43,80,1);
 
  `}
`
export const Col = styled.div`
  ${({ titleBool }) =>
    titleBool &&
    `margin-top:4%; margin-bottom:2%; margin-left:auto; margin-right: auto;`}
`
export const ServiceImg = styled.img`
  /* position: absolute; */
  width: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  /* border: 1px solid red; */

  @media (max-width: 640px) {
    ${({ desktop }) =>
      desktop &&
      `
width: 18%;

`}
    ${({ laptop }) =>
      laptop &&
      `
     width: 23%;
     width: 18%;

`}
${({ tablet }) =>
      tablet &&
      `
width:17%;
width:13%;

  
`}
${({ smartphone }) =>
      smartphone &&
      `
width:15%;
width:11%;

`}
  }

  @media (min-width: 641px) {
    margin: 1%;
    ${({ desktop }) =>
      desktop &&
      `
        width: 23%;





`}

    ${({ laptop }) =>
      laptop &&
      `
     width: 23%;


`}







${({ tablet }) =>
      tablet &&
      `
width:19%;


`}


${({ smartphone }) =>
      smartphone &&
      `
width:17%;


`}
  }

  @media (min-width: 1024px) {
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    ${({ desktop }) =>
      desktop &&
      `
width: 23%;

`}
    ${({ laptop }) =>
      laptop &&
      `
width: 23%;

`}
${({ tablet }) =>
      tablet &&
      `
width:17%;





`}
${({ smartphone }) =>
      smartphone &&
      `
width:15%;
  

`}
  }
`
export const Planets = styled.img`
  display: block;
  position: absolute;

  ${({ purple }) =>
    purple &&
    `
       transform: scale(-1, -1);
  @media (max-width: 640px) {
      opacity:0.8;
  filter: grayscale(0.4);
  margin:3% 0 0 -25%;
  width:120px;

}

@media (min-width: 641px) {
    width: 190px;
  opacity:0.8;
  filter: grayscale(0.4);
  margin:90px 0 0 -190px;

}

@media (min-width: 1024px) {

  margin:50px 0 0 -190px;

}


  
  `}

  ${({ dark }) =>
    dark &&
    `
  @media (max-width: 640px) {
      width:120px;
      margin:11% 0px 0 95%;
          opacity:0.8;
  filter: grayscale(.4);
}

@media (min-width: 641px) {
    width:  170px;
  right:0;
    opacity:0.8;
  filter: grayscale(.4);
margin:0px -120px 0 0;
margin:-30px -180px 0 10px;
}

@media (min-width: 1024px) {

    width: 190px;
margin:-30px -200px 0 10px;

}


  
  `}
`
export const StarsTwo = styled.div`
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  transform: rotate(2deg);
  box-shadow: 788px 1310px #fff, 456px 1074px #fff, 512px 1893px #fff,
    52px 1113px #fff, 244px 612px #fff, 731px 762px #fff, 337px 386px #fff,
    342px 622px #fff, 1949px 753px #fff, 990px 1892px #fff, 1371px 1299px #fff,
    1029px 784px #fff, 1769px 98px #fff, 1090px 1326px #fff, 1825px 1079px #fff,
    701px 658px #fff, 1233px 1002px #fff, 954px 730px #fff, 1675px 1539px #fff,
    631px 685px #fff, 1094px 510px #fff, 860px 376px #fff, 1693px 1039px #fff,
    11px 438px #fff, 777px 610px #fff, 1659px 1394px #fff, 1394px 630px #fff,
    277px 1341px #fff, 851px 473px #fff, 737px 794px #fff, 1423px 389px #fff,
    1314px 216px #fff, 5px 464px #fff, 1271px 1056px #fff, 1662px 223px #fff,
    245px 645px #fff, 819px 1428px #fff, 1919px 495px #fff, 1903px 1311px #fff,
    1254px 741px #fff, 1945px 224px #fff, 1313px 1015px #fff, 1787px 779px #fff,
    153px 121px #fff, 100px 643px #fff, 725px 51px #fff, 460px 1433px #fff,
    1254px 741px #fff, 1945px 224px #fff, 1313px 1015px #fff, 1787px 779px #fff,
    153px 121px #fff, 100px 643px #fff, 725px 51px #fff, 460px 1433px #fff,
    331px 650px #fff, 854px 1305px #fff, 1377px 634px #fff, 1459px 834px #fff,
    1750px 1253px #fff, 879px 821px #fff, 435px 1764px #fff, 256px 1658px #fff,
    904px 1446px #fff, 26px 231px #fff, 464px 340px #fff, 1211px 467px #fff,
    1742px 439px #fff, 1568px 1155px #fff, 8px 47px #fff, 1400px 1722px #fff,
    1878px 131px #fff, 1938px 429px #fff, 731px 133px #fff, 371px 1407px #fff,
    1456px 105px #fff, 431px 1963px #fff, 1762px 645px #fff, 1637px 1417px #fff,
    1208px 1992px #fff, 1010px 1008px #fff, 11px 740px #fff, 1401px 1932px #fff,
    1134px 765px #fff, 1590px 1093px #fff, 1903px 1991px #fff, 685px 302px #fff,
    1183px 1275px #fff, 473px 1117px #fff, 918px 472px #fff, 1952px 1363px #fff,
    1189px 1946px #fff, 225px 1509px #fff, 383px 917px #fff, 1461px 1931px #fff,
    258px 893px #fff, 192px 1091px #fff, 1549px 1724px #fff, 1254px 2px #fff,
    1492px 1741px #fff, 279px 350px #fff, 1074px 561px #fff, 1073px 1px #fff,
    4px 1595px #fff, 1338px 684px #fff, 52px 122px #fff, 495px 1361px #fff,
    1747px 1762px #fff, 1890px 944px #fff, 830px 1332px #fff, 618px 1193px #fff,
    878px 1557px #fff, 809px 1512px #fff, 115px 1552px #fff, 1393px 11px #fff,
    21px 1575px #fff, 1121px 1243px #fff, 1690px 113px #fff, 1263px 1903px #fff,
    404px 1388px #fff, 391px 2000px #fff, 405px 1017px #fff, 1453px 457px #fff,
    530px 1563px #fff, 1002px 734px #fff, 1439px 1705px #fff, 1797px 1092px #fff,
    1024px 537px #fff, 1119px 677px #fff, 1517px 67px #fff, 587px 191px #fff,
    1519px 1549px #fff, 83px 241px #fff, 516px 1855px #fff, 1954px 953px #fff,
    307px 1970px #fff, 551px 1268px #fff, 1890px 1569px #fff, 544px 767px #fff,
    1838px 309px #fff, 199px 63px #fff, 1267px 323px #fff, 1221px 8px #fff,
    753px 1660px #fff, 1576px 1577px #fff, 1138px 1667px #fff, 1878px 131px #fff,
    1938px 429px #fff, 731px 133px #fff, 371px 1407px #fff, 1456px 105px #fff,
    431px 1963px #fff, 1762px 645px #fff, 1637px 1417px #fff, 1837px 1451px #fff,
    560px 851px #fff, 1310px 783px #fff, 730px 1839px #fff, 203px 899px #fff,
    466px 507px #fff, 1868px 1654px #fff, 450px 724px #fff, 307px 1358px #fff,
    710px 894px #fff, 1069px 293px #fff, 487px 1252px #fff, 1967px 1617px #fff,
    316px 1143px #fff, 297px 292px #fff, 649px 466px #fff, 655px 1795px #fff,
    1368px 1610px #fff, 313px 694px #fff, 1470px 1135px #fff, 1244px 96px #fff,
    1749px 1791px #fff, 1290px 1657px #fff, 1816px 155px #fff, 361px 682px #fff,
    1230px 1132px #fff, 892px 143px #fff, 1779px 1079px #fff, 1392px 991px #fff,
    1305px 1241px #fff, 1250px 1969px #fff, 171px 484px #fff, 1364px 695px #fff,
    1021px 1865px #fff, 1375px 1956px #fff, 1170px 94px #fff, 1084px 14px #fff,
    1910px 1897px #fff, 1300px 1185px #fff, 366px 1322px #fff, 1767px 410px #fff,
    519px 1316px #fff, 1321px 1248px #fff, 1974px 1057px #fff, 1679px 245px #fff,
    1860px 1582px #fff, 259px 1959px #fff, 86px 1625px #fff, 1683px 1494px #fff,
    852px 266px #fff, 1297px 432px #fff, 771px 434px #fff, 1236px 641px #fff,
    951px 415px #fff, 1903px 698px #fff, 994px 1037px #fff, 1859px 48px #fff,
    452px 160px #fff, 421px 1569px #fff, 1953px 1327px #fff, 498px 1936px #fff,
    562px 256px #fff;
`
