import React, { useContext } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"
import useToggleState from "../../hooks/useToggleState"
import {
  H1,
  H3,
  H4,
  LandingButton,
  OpenDetailsSuccess,
  Row,
} from "../../globalStyles"

import ContactForm from "./ContactForm"
import {
  FullContainerLocal,
  Col,
  StarsFour,
  StarsFive,
} from "./HomeSuccessSectionStyle"

import SuccessAstronaut from "./SuccessAstronaut"
import Footer from "./CustomFooter"

function HomeSuccessSection({ data }) {
  const { isDarkMode } = useContext(ThemeContext)

  const [contact, contactToggle] = useToggleState(false)

  const {
    buttonText,
    astronaut,
    contactButtonText,
    contactEmailPlaceholder,
    contactNamePlaceholder,
    contactNameMessage,
    mainpara,
    textPlace,
    mailPlace,
    nevPlace,
    webmindEmail,
    webmindTel,
    adatkezeles,
    // secondpara,
    title,
  } = data.allContentfulSuccessPage.nodes[0]
  // console.log(astronaut)
  return (
    <FullContainerLocal
      id="contact"
      dark={isDarkMode}
      className="justify-content-center  mr-auto text-center"
      // className="container-fluid"
    >
      <StarsFour />
      <StarsFive />
      <Row className="row w-100  p-auto m-auto justify-content-center mr-auto text-center">
        <Col
          open={contact}
          titleBoolean
          className="col-12  justify-content-center   col-md-10 col-lg-6 "
        >
          <H1>{title}</H1>
          {!contact && (
            <>
              <H3 className="mt-5 col-sm-10 col-md-10 col-lg-11 mr-auto ml-auto ">
                {mainpara.mainpara}
              </H3>
              <LandingButton
                dark={isDarkMode}
                className="mb-3"
                onClick={contactToggle}
              >
                <H4>{buttonText}</H4>
              </LandingButton>
            </>
          )}

          <OpenDetailsSuccess success={"true"} open={contact}>
            <ContactForm
              landing={true}
              dark={isDarkMode}
              button={contactButtonText}
              message={contactNameMessage}
              email={contactEmailPlaceholder}
              name={contactNamePlaceholder}
              className="m-auto"
              webmindTel={webmindTel}
              webmindEmail={webmindEmail}
              textPlace={textPlace}
              mailPlace={mailPlace}
              nevPlace={nevPlace}
              adatkezeles={adatkezeles}
            />
          </OpenDetailsSuccess>
        </Col>

        <SuccessAstronaut {...astronaut} />
      </Row>

      <Footer landing={"false"} dark={isDarkMode} />
    </FullContainerLocal>
  )
}

export default HomeSuccessSection
