import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { H5 } from "../../globalStyles"
// import astronaut from "../../../images/astronaut.png"

// import slime from "../../../images/planet-slime.svg"
// import desktop from "../../../images/desktop.svg"
// import satellite from "../../../images/satellite.svg"
// import smartphone from "../../../images/smartphone.svg"
// import laptop from "../../../images/laptop.svg"
// import tablet from "../../../images/tablet.svg"
export const buttonBckg = {
  // light: "rgba(85, 89, 257,.4)",
  lightHover: "rgba(85, 82, 247,.5)",
  dark: "rgba(41, 125, 245,.6)",
  // darkHover: "rgba(21, 113, 245,.4)",
}

function Astronauts(props) {
  const {
    astronaut,
    satellite,
    smartphone,
    tablet,
    laptop,
    desktop,
    width,
    imgAlt,
    loaded,
    dark,
    dialog,
  } = props

  return (
    <AstroDiv className="col-12  align-content-center mr-auto  col-md-9 col-lg-4 ">
      <FloatImg
        positions={["-85%", "0px", "0px", "80%"]}
        src={satellite.file.url}
        alt={satellite.title}
        sat={"true"}
      />

      {width < 641 && (
        <>
          <FloatImg
            width={"16%"}
            positions={["50%", "0px", "10px", "10px"]}
            src={desktop}
            alt={imgAlt[1].title}
            pc
          />

          <FloatImg
            positions={["70%", "0px", "0px", "70%"]}
            laptop
            src={laptop}
            alt={imgAlt[2].title}
          />
          <FloatImg
            positions={["10%", "100px", "10px", "110px"]}
            src={smartphone}
            alt={imgAlt[0].title}
            tel
          />
          <FloatImg
            positions={["calc(30vh/2)", "0", "10px", "80%"]}
            src={tablet}
            tablet
            alt={imgAlt[3].title}
          />
        </>
      )}
      {width > 640 && (
        <Dialog
          dark={dark}
          loaded={loaded}
          className="col-sm-5 col-lg-6 col-md-7 text-center"
        >
          <H5 style={{ fontStyle: "italic" }}>{dialog}</H5>
        </Dialog>
      )}
      <Astronaut fluid={astronaut.fluid} alt={astronaut.title} />
    </AstroDiv>
  )
}

export const AstroDiv = styled.div`
  min-height: 45vh; //KITOROLNI HA KESZ MINDEN
  z-index: 0;
  /* position: relative;
  overflow: hidden; */
  /* background: green; */
  @media (max-width: 640px) {
  }

  @media (min-width: 641px) {
    margin-top: 5%;
    margin-left: 20%;
    /* overflow: hidden; */
    min-height: 45vh;
    min-width: auto;
  }

  @media (min-width: 1024px) {
    margin-top: 15%;
    margin-left: 5%;

    /* overflow: hidden; */
  }
`

export const Astronaut = styled(Img)`
  display: block;
  position: absolute;
  width: 80%;
  animation: astronaut 50s linear infinite;
  transition: all 300ms;

  @media (max-width: 640px) {
    top: 50px;
    width: 45%;
    left: 110px;

    @keyframes astronaut {
      0% {
        transform: translateY(0px) rotate(0deg) translateX(0px);
      }
      50% {
        transform: translateY(45px) rotate(10deg) translateX(25px);
      }
      100% {
        transform: translateY(0px) rotate(0deg) translateX(0px);
      }
    }
  }

  @media (min-width: 641px) {
    width: 50%;
    margin-top: 0%;
    margin-left: 15%;
    max-width: 280px;

    @keyframes astronaut {
      0% {
        transform: translateY(0px) rotate(0deg) translateX(0px);
      }
      50% {
        transform: translateY(60px) rotate(10deg) translateX(25px);
      }
      100% {
        transform: translateY(0px) rotate(0deg) translateX(0px);
      }
    }
  }

  @media (min-width: 1024px) {
    margin-top: auto;
    width: 65%;
    top: 30px;
    margin-left: -15px;

    max-width: 300px;

    @keyframes astronaut {
      0% {
        transform: translateY(0px) rotate(0deg) translateX(0px);
      }
      50% {
        transform: translateY(70px) rotate(-10deg) translateX(25px);
      }
      100% {
        transform: translateY(0px) rotate(0deg) translateX(0px);
      }
    }
  }
`

export const FloatImg = styled.img`
  display: block;
  position: absolute;
  width: 7%;

  transition: all 300ms;
  margin: ${props => props.margin};
  top: ${props => props.positions[0]};
  left: ${props => props.positions[3]};
  right: ${props => props.positions[1]};
  bottom: ${props => props.positions[2]};

  @media (max-width: 640px) {
    width: 9%;
    width: ${props => props.width};
  }

  @media (min-width: 641px) {
    width: 12%;
  }

  @media (min-width: 1024px) {
    width: 8%;
  }

  ${({ tel }) =>
    tel &&
    `

      animation: tel 40s linear infinite;
    @keyframes tel {
    0% {
      transform: translateY(0px)  rotate(25deg) translateX(0px);
    }
    50% {
      transform: translateY(40px) rotate(0deg) translateX(25px);
    }
    100% {
      transform: translateY(0px) rotate(25deg) translateX(0px);
    }
  }`}

  ${({ pc }) =>
    pc &&
    `
@media (max-width: 640px) {
  width:13%;
}

@media (min-width: 641px) {
      width:15%;   
      margin-top:15%;
          margin-left:-10%;
}

@media (min-width: 1024px) {
    width:13%;
    margin-top:15%;
    margin-left:-10%;
}





      animation: pc 45s linear infinite;
    @keyframes pc {
    0% {
      transform: translateY(0px)  rotate(-25deg) translateX(0px);
    }
    50% {
      transform: translateY(40px) rotate(0deg) translateX(25px);
    }
    100% {
      transform: translateY(0px) rotate(-25deg) translateX(0px);
    }
  }`}

    ${({ sat }) =>
    sat &&
    `
@media (max-width: 640px) {
  margin-top:-50px;

}





      animation: sat 55s linear infinite;

@media (min-width: 641px) {
  margin-left:50px;
  
width:7%;}

              @media (min-width: 1024px) {
      top: -30%;
      width:8%;

};
    
    @keyframes sat {
    0% {
      transform: translateY(0px)  rotate(0deg) translateX(0px);
    }
    50% {
      transform: translateY(20px) rotate(180deg) translateX(25px);
    }
    100% {
      transform: translateY(0px) rotate(360deg) translateX(0px);
    }
  }`}

    ${({ laptop }) =>
    laptop &&
    `
@media (max-width: 640px) {
  width:13%;
top:165px;
}

@media (min-width: 641px) {
  width:17%;
}

@media (min-width: 1024px) {
  width:14%;
  margin-top:18%;
}


    
      animation: laptop 35s linear infinite;
    @keyframes laptop {
    0% {
      transform: translateY(10px)  rotate(25deg) translateX(30px);
    }
    50% {
      transform: translateY(40px) rotate(0deg) translateX(65px);
    }
    100% {
      transform: translateY(10px) rotate(25deg) translateX(30px);
    }
  }`}
    ${({ tablet }) =>
    tablet &&
    `

@media (max-width: 640px) {  margin-top:-15%;
}

@media (min-width: 641px) {
  margin-top:-15%;
}

@media (min-width: 1024px) {
}


      animation: tablet 55s linear infinite;
    @keyframes tablet {
    0% {
      transform: translateY(30px)  rotate(0deg) translateX(0px);
    }
    50% {
      transform: translateY(-20px) rotate(180deg) translateX(25px);
    }
    100% {
      transform: translateY(30px) rotate(360deg) translateX(0px);
    }
  }`}
`

export const Dialog = styled.div`
  /* height: 80px;
  width: 130px; */
  transition: all 600ms;
  height: auto;
  padding: 25px;
  background: ${({ dark }) =>
    !dark ? buttonBckg.lightHover : buttonBckg.dark};
  /* border-radius: 15px; */

  position: absolute;
  z-index: 50;
  ${({ loaded }) =>
    loaded
      ? `  opacity: 1; margin-left:-20px;margin-top:0px;`
      : `opacity:0; margin-left:60px; margin-top:30px;`};

  @media (min-width: 641px) {
    /* bottom: 100px; */
    top: 50px;
    left: -140px;
  }

  @media (min-width: 1024px) {
    top: -50px;
    left: 0px;
  }
  animation: dialog 20s linear infinite;
  @keyframes dialog {
    0% {
      transform: translate(0px, 0px);
      border-radius: 23% 77% 74% 26% / 25% 68% 32% 75%;
    }
    50% {
      transform: translate(15px, -30px);
      border-radius: 64% 36% 42% 58% / 53% 30% 70% 47%;
    }
    100% {
      transform: translate(0px, 0px);
      border-radius: 23% 77% 74% 26% / 25% 68% 32% 75%;
    }
  }
  /* opacity: 1; */
`
export default Astronauts
