import styled from "styled-components"

export const StarsOne = styled.div`
  transition: all 300ms;
  opacity: 1;
  overflow: hidden;
  display: block;
  width: 1px;
  height: 1px;
  background: transparent;
  border-radius: 80%;
  box-shadow: 1015px 1325px #fff, 1658px 369px #fff, 1343px 205px #fff,
    1995px 1201px #fff, 1925px 1447px #fff, 1045px 251px #fff,
    1073px 1954px #fff, 1551px 708px #fff, 642px 962px #fff, 561px 213px #fff,
    111px 674px #fff, 1237px 1633px #fff, 1937px 696px #fff, 856px 334px #fff,
    1405px 102px #fff, 1363px 1599px #fff, 210px 1008px #fff, 1295px 952px #fff,
    642px 1919px #fff, 335px 821px #fff, 1814px 1026px #fff, 1877px 1537px #fff,
    1195px 1135px #fff, 1782px 1176px #fff, 1044px 966px #fff, 976px 177px #fff,
    737px 415px #fff, 789px 312px #fff, 1258px 1580px #fff, 1866px 958px #fff,
    425px 1125px #fff, 1525px 1541px #fff, 448px 362px #fff, 623px 918px #fff,
    1653px 726px #fff, 1687px 1249px #fff, 1385px 1857px #fff, 1692px 752px #fff,
    1955px 481px #fff, 1087px 1537px #fff, 670px 1493px #fff, 520px 1319px #fff,
    1226px 525px #fff, 1018px 1889px #fff, 719px 845px #fff, 663px 1067px #fff,
    980px 1715px #fff, 80px 819px #fff, 600px 476px #fff, 775px 1144px #fff,
    684px 1851px #fff, 1739px 1769px #fff, 374px 1998px #fff, 1237px 1128px #fff,
    251px 1114px #fff, 544px 1624px #fff, 469px 1399px #fff, 1311px 835px #fff,
    1614px 672px #fff, 66px 403px #fff, 1822px 1241px #fff, 1626px 1976px #fff,
    1077px 1793px #fff, 1557px 1609px #fff, 189px 1749px #fff,
    1452px 1484px #fff, 1430px 317px #fff, 1448px 82px #fff, 55px 1586px #fff,
    273px 1662px #fff, 460px 676px #fff, 12px 1208px #fff, 644px 624px #fff,
    561px 882px #fff, 1611px 465px #fff, 1838px 1837px #fff, 1287px 266px #fff,
    1206px 605px #fff, 289px 1358px #fff, 829px 1482px #fff, 1220px 1726px #fff,
    1689px 171px #fff, 1758px 1432px #fff, 10px 1067px #fff, 539px 1259px #fff,
    256px 282px #fff, 528px 201px #fff, 646px 305px #fff, 362px 949px #fff,
    1452px 484px #fff, 964px 1505px #fff, 952px 1818px #fff, 1507px 350px #fff,
    1036px 540px #fff, 1502px 83px #fff, 1540px 678px #fff, 1503px 1706px #fff,
    15px 919px #fff, 1760px 1151px #fff, 1560px 257px #fff, 1678px 1717px #fff,
    1547px 435px #fff, 1932px 251px #fff, 450px 1996px #fff, 875px 1038px #fff,
    774px 1119px #fff, 528px 884px #fff, 465px 1153px #fff, 31px 477px #fff,
    217px 1181px #fff, 1443px 1691px #fff, 577px 85px #fff, 287px 134px #fff,
    201px 1034px #fff, 1804px 1489px #fff, 1551px 1894px #fff, 42px 360px #fff,
    291px 1164px #fff, 616px 547px #fff, 661px 678px #fff, 1315px 1804px #fff,
    442px 1797px #fff, 1158px 361px #fff, 652px 464px #fff, 1558px 1262px #fff,
    241px 1356px #fff, 479px 44px #fff, 1636px 502px #fff, 1041px 119px #fff,
    704px 1643px #fff, 136px 200px #fff, 653px 120px #fff, 1072px 1910px #fff,
    1712px 1948px #fff, 219px 61px #fff, 378px 180px #fff, 1792px 704px #fff,
    1048px 1752px #fff, 1879px 180px #fff, 945px 1200px #fff, 1378px 1925px #fff,
    1745px 1704px #fff, 1411px 996px #fff, 604px 181px #fff, 339px 817px #fff,
    1009px 1513px #fff, 1703px 727px #fff, 1450px 1779px #fff, 75px 1602px #fff,
    290px 1133px #fff, 41px 713px #fff, 136px 1690px #fff, 1289px 113px #fff,
    1217px 1131px #fff, 1179px 1550px #fff, 1134px 1820px #fff, 614px 152px #fff,
    266px 286px #fff, 792px 1103px #fff, 839px 569px #fff, 715px 1036px #fff,
    1748px 1979px #fff, 1012px 1716px #fff, 1242px 428px #fff, 690px 608px #fff,
    1059px 1100px #fff, 1135px 1049px #fff, 1174px 254px #fff,
    1192px 1351px #fff, 1805px 1951px #fff, 479px 1874px #fff,
    1238px 1311px #fff, 451px 230px #fff, 1771px 380px #fff, 188px 64px #fff,
    914px 750px #fff, 1500px 1885px #fff, 1476px 920px #fff, 1621px 348px #fff,
    981px 529px #fff, 235px 1296px #fff, 1546px 170px #fff, 263px 466px #fff,
    1919px 1542px #fff, 263px 1997px #fff, 1093px 1947px #fff, 585px 1319px #fff,
    372px 1360px #fff, 945px 1502px #fff, 1966px 239px #fff, 101px 356px #fff,
    1609px 245px #fff, 634px 690px #fff, 1835px 1601px #fff, 364px 1543px #fff,
    1130px 1320px #fff, 84px 516px #fff, 351px 500px #fff, 976px 510px #fff,
    198px 1959px #fff, 1562px 865px #fff, 991px 1200px #fff, 1240px 1036px #fff,
    19px 720px #fff, 305px 1549px #fff, 1683px 222px #fff, 1065px 1401px #fff,
    1247px 1451px #fff, 1337px 192px #fff, 350px 1125px #fff, 1248px 151px #fff,
    165px 1553px #fff, 892px 1980px #fff, 1699px 1157px #fff, 221px 1688px #fff,
    233px 283px #fff, 1559px 1309px #fff, 1408px 1128px #fff, 354px 1478px #fff,
    1110px 395px #fff, 904px 1529px #fff, 426px 598px #fff, 657px 696px #fff,
    1084px 626px #fff, 1163px 939px #fff, 1801px 1681px #fff, 934px 1115px #fff,
    1467px 204px #fff, 1035px 159px #fff, 122px 1564px #fff, 434px 671px #fff,
    1835px 135px #fff, 613px 363px #fff, 1936px 591px #fff, 255px 483px #fff,
    1730px 1788px #fff, 1460px 160px #fff, 13px 305px #fff, 861px 33px #fff,
    1981px 1100px #fff, 1736px 1023px #fff, 367px 1076px #fff,
    1990px 1005px #fff, 1738px 1384px #fff, 1724px 1722px #fff,
    794px 1559px #fff, 1279px 1335px #fff, 1331px 1652px #fff, 368px 1684px #fff,
    699px 403px #fff, 443px 801px #fff, 17px 762px #fff, 1482px 748px #fff,
    24px 1753px #fff, 650px 943px #fff, 965px 1634px #fff, 1744px 911px #fff,
    612px 1351px #fff, 1720px 1973px #fff, 53px 64px #fff, 684px 439px #fff,
    690px 1556px #fff, 287px 1727px #fff, 930px 810px #fff, 1000px 647px #fff,
    892px 1729px #fff, 1480px 154px #fff, 1443px 1333px #fff, 938px 1992px #fff,
    596px 249px #fff, 1034px 1427px #fff, 1772px 147px #fff, 1160px 1697px #fff,
    1534px 302px #fff, 1193px 767px #fff, 236px 777px #fff, 220px 737px #fff,
    201px 1281px #fff, 1674px 1026px #fff, 423px 144px #fff, 178px 1665px #fff,
    732px 997px #fff, 149px 1628px #fff, 177px 641px #fff, 689px 57px #fff,
    1876px 250px #fff, 1031px 892px #fff, 718px 715px #fff, 1598px 1403px #fff,
    1214px 1401px #fff, 834px 251px #fff, 1215px 817px #fff, 607px 236px #fff,
    1881px 1456px #fff, 142px 97px #fff, 260px 529px #fff, 696px 241px #fff,
    1393px 943px #fff, 1847px 1550px #fff, 967px 1802px #fff, 1504px 493px #fff,
    36px 1754px #fff, 1161px 457px #fff, 572px 151px #fff, 1019px 710px #fff,
    814px 1627px #fff, 1865px 1898px #fff, 645px 1240px #fff, 393px 1763px #fff,
    1172px 71px #fff, 619px 657px #fff, 310px 1051px #fff, 78px 1463px #fff,
    588px 289px #fff, 1646px 1062px #fff, 111px 672px #fff, 1138px 605px #fff,
    1326px 300px #fff, 1549px 1078px #fff, 1873px 1858px #fff, 481px 457px #fff,
    1438px 1659px #fff, 383px 399px #fff, 1908px 1722px #fff, 514px 1173px #fff,
    851px 1666px #fff, 1564px 909px #fff, 1949px 525px #fff, 1000px 1192px #fff,
    578px 1564px #fff, 424px 1690px #fff, 1242px 939px #fff, 1529px 1433px #fff,
    1924px 249px #fff, 1515px 855px #fff, 143px 1109px #fff, 797px 1452px #fff,
    1380px 1820px #fff, 1482px 101px #fff, 20px 19px #fff, 914px 1472px #fff,
    1210px 65px #fff, 1027px 106px #fff, 79px 49px #fff, 1307px 899px #fff,
    238px 1017px #fff, 337px 1777px #fff, 514px 1092px #fff, 1882px 346px #fff,
    873px 674px #fff, 1636px 550px #fff, 1020px 1349px #fff, 1604px 1237px #fff,
    473px 757px #fff, 1865px 386px #fff, 1140px 912px #fff, 794px 710px #fff,
    1881px 219px #fff, 985px 1011px #fff, 1327px 1452px #fff, 495px 595px #fff,
    1434px 140px #fff, 1676px 994px #fff, 282px 550px #fff, 1968px 1613px #fff,
    1916px 542px #fff, 1612px 612px #fff, 1751px 649px #fff, 506px 1917px #fff,
    373px 1236px #fff, 699px 1777px #fff, 821px 549px #fff, 1693px 816px #fff,
    1127px 1751px #fff, 1272px 114px #fff, 1314px 1566px #fff, 1956px 380px #fff,
    912px 536px #fff, 294px 1970px #fff, 1592px 688px #fff, 800px 1223px #fff,
    656px 1882px #fff, 1351px 1631px #fff, 257px 269px #fff, 522px 280px #fff,
    613px 211px #fff, 1473px 1214px #fff, 1472px 906px #fff, 267px 314px #fff,
    47px 1296px #fff, 1730px 349px #fff, 325px 331px #fff, 5px 1066px #fff,
    1553px 553px #fff, 809px 277px #fff, 1599px 599px #fff, 1405px 484px #fff,
    478px 726px #fff, 1967px 175px #fff, 635px 1284px #fff, 1368px 449px #fff,
    943px 614px #fff, 266px 1737px #fff, 1078px 106px #fff, 1205px 147px #fff,
    1958px 911px #fff, 553px 152px #fff, 684px 542px #fff, 943px 988px #fff,
    1065px 958px #fff, 167px 535px #fff, 327px 1416px #fff, 399px 897px #fff,
    910px 1318px #fff, 1396px 800px #fff, 1761px 1196px #fff, 1093px 1995px #fff,
    622px 300px #fff, 1107px 1629px #fff, 758px 81px #fff, 1244px 1674px #fff,
    1520px 245px #fff, 851px 1368px #fff, 761px 960px #fff, 84px 191px #fff,
    1657px 740px #fff, 230px 1463px #fff, 650px 1440px #fff, 130px 486px #fff,
    1596px 1000px #fff, 595px 75px #fff, 38px 508px #fff, 751px 689px #fff,
    1191px 957px #fff, 1762px 350px #fff, 1871px 530px #fff, 316px 1311px #fff,
    761px 1853px #fff, 215px 341px #fff, 1955px 1355px #fff, 1875px 1480px #fff,
    1365px 429px #fff, 1543px 1213px #fff, 1864px 1183px #fff,
    1755px 1272px #fff, 1514px 700px #fff, 1630px 1991px #fff, 514px 42px #fff,
    280px 855px #fff, 1155px 699px #fff, 1120px 1949px #fff, 603px 7px #fff,
    1056px 100px #fff, 654px 746px #fff, 1886px 364px #fff, 88px 1789px #fff,
    1023px 761px #fff, 1904px 1570px #fff, 1275px 1656px #fff, 1159px 644px #fff,
    1993px 770px #fff, 1016px 1004px #fff, 1990px 1945px #fff, 687px 1299px #fff,
    114px 1332px #fff, 371px 496px #fff, 700px 1897px #fff, 292px 1489px #fff,
    93px 1231px #fff, 423px 702px #fff, 1569px 229px #fff, 929px 1336px #fff,
    1863px 1026px #fff, 1177px 376px #fff, 1426px 790px #fff, 816px 1698px #fff,
    724px 65px #fff, 468px 1448px #fff, 1106px 230px #fff, 1108px 1813px #fff,
    911px 1730px #fff, 1155px 709px #fff, 1341px 266px #fff, 171px 134px #fff,
    1927px 1718px #fff, 1028px 1726px #fff, 934px 278px #fff, 601px 134px #fff,
    1537px 616px #fff, 50px 764px #fff, 428px 148px #fff, 1568px 62px #fff,
    1584px 287px #fff, 911px 1506px #fff, 553px 907px #fff, 602px 1294px #fff,
    657px 1313px #fff, 6px 73px #fff, 467px 1030px #fff, 1201px 1660px #fff,
    1784px 1198px #fff, 1605px 84px #fff, 377px 1749px #fff, 463px 1423px #fff,
    1725px 487px #fff, 1874px 1493px #fff, 670px 1244px #fff, 1326px 1655px #fff,
    1394px 632px #fff, 208px 1424px #fff, 254px 1750px #fff, 405px 29px #fff,
    1258px 1710px #fff, 114px 336px #fff, 1698px 810px #fff, 640px 849px #fff,
    1312px 198px #fff, 1668px 1237px #fff, 1996px 669px #fff, 623px 110px #fff,
    1277px 1257px #fff, 1019px 24px #fff, 1923px 668px #fff, 1236px 23px #fff,
    1724px 1974px #fff, 1440px 605px #fff, 644px 1255px #fff, 1762px 590px #fff,
    1364px 992px #fff, 1025px 128px #fff, 1320px 1078px #fff, 75px 721px #fff,
    1013px 129px #fff, 716px 1896px #fff, 1332px 289px #fff, 80px 567px #fff,
    1019px 1878px #fff, 478px 769px #fff, 705px 1515px #fff, 853px 375px #fff,
    397px 1362px #fff, 635px 1664px #fff, 147px 1402px #fff, 82px 1412px #fff,
    1187px 645px #fff, 1480px 1429px #fff, 521px 1610px #fff, 480px 290px #fff,
    1673px 1481px #fff, 30px 1827px #fff, 1989px 1416px #fff, 1458px 756px #fff,
    401px 1px #fff, 1326px 121px #fff, 1999px 1423px #fff, 1822px 48px #fff,
    1443px 1029px #fff, 240px 1102px #fff, 1740px 1232px #fff, 196px 1759px #fff,
    1551px 385px #fff, 1380px 954px #fff, 1889px 902px #fff, 1559px 1998px #fff,
    1636px 1425px #fff, 1021px 1001px #fff, 435px 1440px #fff,
    1309px 1391px #fff, 1531px 1976px #fff, 711px 442px #fff, 904px 1922px #fff,
    1894px 879px #fff, 606px 1391px #fff, 550px 1284px #fff, 1500px 164px #fff,
    1190px 182px #fff, 1544px 656px #fff, 1237px 1133px #fff, 1436px 827px #fff,
    1088px 745px #fff, 1157px 1086px #fff, 852px 1998px #fff, 1839px 1246px #fff,
    570px 944px #fff, 1110px 1935px #fff, 1818px 1550px #fff, 1093px 1251px #fff,
    143px 714px #fff, 459px 985px #fff, 1931px 919px #fff, 1132px 817px #fff,
    588px 1539px #fff, 223px 1589px #fff, 730px 515px #fff, 540px 206px #fff,
    1607px 278px #fff, 1180px 1310px #fff, 679px 471px #fff, 497px 1778px #fff,
    813px 1343px #fff, 24px 1203px #fff, 584px 950px #fff, 528px 105px #fff,
    1060px 15px #fff, 1615px 303px #fff, 775px 876px #fff, 1580px 1465px #fff,
    1313px 454px #fff, 1282px 1666px #fff, 1191px 1914px #fff, 939px 701px #fff,
    1132px 1001px #fff, 1057px 1577px #fff, 273px 360px #fff, 1123px 1694px #fff,
    335px 999px #fff, 1688px 1924px #fff, 973px 807px #fff, 1915px 1639px #fff,
    188px 655px #fff, 338px 1007px #fff, 1098px 1885px #fff, 270px 1786px #fff,
    1999px 1543px #fff, 378px 702px #fff, 43px 1044px #fff, 1512px 731px #fff,
    19px 1903px #fff, 687px 1422px #fff, 583px 268px #fff, 994px 1716px #fff,
    1841px 1657px #fff, 30px 873px #fff, 1202px 1872px #fff, 248px 407px #fff,
    594px 34px #fff, 846px 1050px #fff, 182px 902px #fff, 1469px 1209px #fff,
    642px 1385px #fff, 342px 165px #fff, 566px 1205px #fff, 958px 1568px #fff,
    1709px 328px #fff, 34px 1186px #fff, 1593px 566px #fff, 904px 219px #fff,
    72px 1291px #fff, 1798px 258px #fff, 759px 30px #fff, 1634px 1539px #fff,
    406px 1376px #fff, 61px 337px #fff, 386px 1270px #fff, 1589px 1040px #fff,
    84px 1133px #fff, 1609px 115px #fff, 1494px 1259px #fff, 822px 966px #fff,
    1039px 533px #fff, 1661px 1986px #fff, 1250px 1636px #fff, 289px 451px #fff,
    614px 637px #fff, 786px 1099px #fff, 1614px 852px #fff, 718px 955px #fff,
    134px 1424px #fff, 1672px 714px #fff, 1911px 333px #fff, 195px 1387px #fff,
    151px 542px #fff, 351px 169px #fff, 1921px 883px #fff, 1626px 1927px #fff,
    889px 973px #fff, 1148px 27px #fff, 1764px 304px #fff, 1451px 119px #fff,
    1032px 14px #fff, 33px 1481px #fff, 1056px 1390px #fff, 1629px 413px #fff,
    953px 244px #fff, 1865px 1278px #fff, 1530px 1539px #fff, 840px 280px #fff,
    1852px 1945px #fff, 356px 384px #fff, 44px 47px #fff, 1986px 1337px #fff,
    214px 1194px #fff, 741px 1715px #fff, 1686px 967px #fff, 1974px 517px #fff,
    924px 324px #fff, 1592px 714px #fff, 1973px 488px #fff, 1602px 868px #fff,
    448px 300px #fff;
  /* animation: animStar 50s linear infinite; */
  animation: animStar linear infinite;

  /* &:after {
    content: " ";
    position: absolute;
    top: 100vh;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1015px 1325px #fff, 1658px 369px #fff, 1343px 205px #fff,
      1995px 1201px #fff, 1925px 1447px #fff, 1045px 251px #fff,
      1073px 1954px #fff, 1551px 708px #fff, 642px 962px #fff, 561px 213px #fff,
      111px 674px #fff, 1237px 1633px #fff, 1937px 696px #fff, 856px 334px #fff,
      1405px 102px #fff, 1363px 1599px #fff, 210px 1008px #fff,
      1295px 952px #fff, 642px 1919px #fff, 335px 821px #fff, 1814px 1026px #fff,
      1877px 1537px #fff, 1195px 1135px #fff, 1782px 1176px #fff,
      1044px 966px #fff, 976px 177px #fff, 737px 415px #fff, 789px 312px #fff,
      1258px 1580px #fff, 1866px 958px #fff, 425px 1125px #fff,
      1525px 1541px #fff, 448px 362px #fff, 623px 918px #fff, 1653px 726px #fff,
      1687px 1249px #fff, 1385px 1857px #fff, 1692px 752px #fff,
      1955px 481px #fff, 1087px 1537px #fff, 670px 1493px #fff,
      520px 1319px #fff, 1226px 525px #fff, 1018px 1889px #fff, 719px 845px #fff,
      663px 1067px #fff, 980px 1715px #fff, 80px 819px #fff, 600px 476px #fff,
      775px 1144px #fff, 684px 1851px #fff, 1739px 1769px #fff,
      374px 1998px #fff, 1237px 1128px #fff, 251px 1114px #fff,
      544px 1624px #fff, 469px 1399px #fff, 1311px 835px #fff, 1614px 672px #fff,
      66px 403px #fff, 1822px 1241px #fff, 1626px 1976px #fff,
      1077px 1793px #fff, 1557px 1609px #fff, 189px 1749px #fff,
      1452px 1484px #fff, 1430px 317px #fff, 1448px 82px #fff, 55px 1586px #fff,
      273px 1662px #fff, 460px 676px #fff, 12px 1208px #fff, 644px 624px #fff,
      561px 882px #fff, 1611px 465px #fff, 1838px 1837px #fff, 1287px 266px #fff,
      1206px 605px #fff, 289px 1358px #fff, 829px 1482px #fff,
      1220px 1726px #fff, 1689px 171px #fff, 1758px 1432px #fff,
      10px 1067px #fff, 539px 1259px #fff, 256px 282px #fff, 528px 201px #fff,
      646px 305px #fff, 362px 949px #fff, 1452px 484px #fff, 964px 1505px #fff,
      952px 1818px #fff, 1507px 350px #fff, 1036px 540px #fff, 1502px 83px #fff,
      1540px 678px #fff, 1503px 1706px #fff, 15px 919px #fff, 1760px 1151px #fff,
      1560px 257px #fff, 1678px 1717px #fff, 1547px 435px #fff,
      1932px 251px #fff, 450px 1996px #fff, 875px 1038px #fff, 774px 1119px #fff,
      528px 884px #fff, 465px 1153px #fff, 31px 477px #fff, 217px 1181px #fff,
      1443px 1691px #fff, 577px 85px #fff, 287px 134px #fff, 201px 1034px #fff,
      1804px 1489px #fff, 1551px 1894px #fff, 42px 360px #fff, 291px 1164px #fff,
      616px 547px #fff, 661px 678px #fff, 1315px 1804px #fff, 442px 1797px #fff,
      1158px 361px #fff, 652px 464px #fff, 1558px 1262px #fff, 241px 1356px #fff,
      479px 44px #fff, 1636px 502px #fff, 1041px 119px #fff, 704px 1643px #fff,
      136px 200px #fff, 653px 120px #fff, 1072px 1910px #fff, 1712px 1948px #fff,
      219px 61px #fff, 378px 180px #fff, 1792px 704px #fff, 1048px 1752px #fff,
      1879px 180px #fff, 945px 1200px #fff, 1378px 1925px #fff,
      1745px 1704px #fff, 1411px 996px #fff, 604px 181px #fff, 339px 817px #fff,
      1009px 1513px #fff, 1703px 727px #fff, 1450px 1779px #fff,
      75px 1602px #fff, 290px 1133px #fff, 41px 713px #fff, 136px 1690px #fff,
      1289px 113px #fff, 1217px 1131px #fff, 1179px 1550px #fff,
      1134px 1820px #fff, 614px 152px #fff, 266px 286px #fff, 792px 1103px #fff,
      839px 569px #fff, 715px 1036px #fff, 1748px 1979px #fff,
      1012px 1716px #fff, 1242px 428px #fff, 690px 608px #fff,
      1059px 1100px #fff, 1135px 1049px #fff, 1174px 254px #fff,
      1192px 1351px #fff, 1805px 1951px #fff, 479px 1874px #fff,
      1238px 1311px #fff, 451px 230px #fff, 1771px 380px #fff, 188px 64px #fff,
      914px 750px #fff, 1500px 1885px #fff, 1476px 920px #fff, 1621px 348px #fff,
      981px 529px #fff, 235px 1296px #fff, 1546px 170px #fff, 263px 466px #fff,
      1919px 1542px #fff, 263px 1997px #fff, 1093px 1947px #fff,
      585px 1319px #fff, 372px 1360px #fff, 945px 1502px #fff, 1966px 239px #fff,
      101px 356px #fff, 1609px 245px #fff, 634px 690px #fff, 1835px 1601px #fff,
      364px 1543px #fff, 1130px 1320px #fff, 84px 516px #fff, 351px 500px #fff,
      976px 510px #fff, 198px 1959px #fff, 1562px 865px #fff, 991px 1200px #fff,
      1240px 1036px #fff, 19px 720px #fff, 305px 1549px #fff, 1683px 222px #fff,
      1065px 1401px #fff, 1247px 1451px #fff, 1337px 192px #fff,
      350px 1125px #fff, 1248px 151px #fff, 165px 1553px #fff, 892px 1980px #fff,
      1699px 1157px #fff, 221px 1688px #fff, 233px 283px #fff,
      1559px 1309px #fff, 1408px 1128px #fff, 354px 1478px #fff,
      1110px 395px #fff, 904px 1529px #fff, 426px 598px #fff, 657px 696px #fff,
      1084px 626px #fff, 1163px 939px #fff, 1801px 1681px #fff,
      934px 1115px #fff, 1467px 204px #fff, 1035px 159px #fff, 122px 1564px #fff,
      434px 671px #fff, 1835px 135px #fff, 613px 363px #fff, 1936px 591px #fff,
      255px 483px #fff, 1730px 1788px #fff, 1460px 160px #fff, 13px 305px #fff,
      861px 33px #fff, 1981px 1100px #fff, 1736px 1023px #fff, 367px 1076px #fff,
      1990px 1005px #fff, 1738px 1384px #fff, 1724px 1722px #fff,
      794px 1559px #fff, 1279px 1335px #fff, 1331px 1652px #fff,
      368px 1684px #fff, 699px 403px #fff, 443px 801px #fff, 17px 762px #fff,
      1482px 748px #fff, 24px 1753px #fff, 650px 943px #fff, 965px 1634px #fff,
      1744px 911px #fff, 612px 1351px #fff, 1720px 1973px #fff, 53px 64px #fff,
      684px 439px #fff, 690px 1556px #fff, 287px 1727px #fff, 930px 810px #fff,
      1000px 647px #fff, 892px 1729px #fff, 1480px 154px #fff,
      1443px 1333px #fff, 938px 1992px #fff, 596px 249px #fff,
      1034px 1427px #fff, 1772px 147px #fff, 1160px 1697px #fff,
      1534px 302px #fff, 1193px 767px #fff, 236px 777px #fff, 220px 737px #fff,
      201px 1281px #fff, 1674px 1026px #fff, 423px 144px #fff, 178px 1665px #fff,
      732px 997px #fff, 149px 1628px #fff, 177px 641px #fff, 689px 57px #fff,
      1876px 250px #fff, 1031px 892px #fff, 718px 715px #fff, 1598px 1403px #fff,
      1214px 1401px #fff, 834px 251px #fff, 1215px 817px #fff, 607px 236px #fff,
      1881px 1456px #fff, 142px 97px #fff, 260px 529px #fff, 696px 241px #fff,
      1393px 943px #fff, 1847px 1550px #fff, 967px 1802px #fff,
      1504px 493px #fff, 36px 1754px #fff, 1161px 457px #fff, 572px 151px #fff,
      1019px 710px #fff, 814px 1627px #fff, 1865px 1898px #fff,
      645px 1240px #fff, 393px 1763px #fff, 1172px 71px #fff, 619px 657px #fff,
      310px 1051px #fff, 78px 1463px #fff, 588px 289px #fff, 1646px 1062px #fff,
      111px 672px #fff, 1138px 605px #fff, 1326px 300px #fff, 1549px 1078px #fff,
      1873px 1858px #fff, 481px 457px #fff, 1438px 1659px #fff, 383px 399px #fff,
      1908px 1722px #fff, 514px 1173px #fff, 851px 1666px #fff,
      1564px 909px #fff, 1949px 525px #fff, 1000px 1192px #fff,
      578px 1564px #fff, 424px 1690px #fff, 1242px 939px #fff,
      1529px 1433px #fff, 1924px 249px #fff, 1515px 855px #fff,
      143px 1109px #fff, 797px 1452px #fff, 1380px 1820px #fff,
      1482px 101px #fff, 20px 19px #fff, 914px 1472px #fff, 1210px 65px #fff,
      1027px 106px #fff, 79px 49px #fff, 1307px 899px #fff, 238px 1017px #fff,
      337px 1777px #fff, 514px 1092px #fff, 1882px 346px #fff, 873px 674px #fff,
      1636px 550px #fff, 1020px 1349px #fff, 1604px 1237px #fff,
      473px 757px #fff, 1865px 386px #fff, 1140px 912px #fff, 794px 710px #fff,
      1881px 219px #fff, 985px 1011px #fff, 1327px 1452px #fff, 495px 595px #fff,
      1434px 140px #fff, 1676px 994px #fff, 282px 550px #fff, 1968px 1613px #fff,
      1916px 542px #fff, 1612px 612px #fff, 1751px 649px #fff, 506px 1917px #fff,
      373px 1236px #fff, 699px 1777px #fff, 821px 549px #fff, 1693px 816px #fff,
      1127px 1751px #fff, 1272px 114px #fff, 1314px 1566px #fff,
      1956px 380px #fff, 912px 536px #fff, 294px 1970px #fff, 1592px 688px #fff,
      800px 1223px #fff, 656px 1882px #fff, 1351px 1631px #fff, 257px 269px #fff,
      522px 280px #fff, 613px 211px #fff, 1473px 1214px #fff, 1472px 906px #fff,
      267px 314px #fff, 47px 1296px #fff, 1730px 349px #fff, 325px 331px #fff,
      5px 1066px #fff, 1553px 553px #fff, 809px 277px #fff, 1599px 599px #fff,
      1405px 484px #fff, 478px 726px #fff, 1967px 175px #fff, 635px 1284px #fff,
      1368px 449px #fff, 943px 614px #fff, 266px 1737px #fff, 1078px 106px #fff,
      1205px 147px #fff, 1958px 911px #fff, 553px 152px #fff, 684px 542px #fff,
      943px 988px #fff, 1065px 958px #fff, 167px 535px #fff, 327px 1416px #fff,
      399px 897px #fff, 910px 1318px #fff, 1396px 800px #fff, 1761px 1196px #fff,
      1093px 1995px #fff, 622px 300px #fff, 1107px 1629px #fff, 758px 81px #fff,
      1244px 1674px #fff, 1520px 245px #fff, 851px 1368px #fff, 761px 960px #fff,
      84px 191px #fff, 1657px 740px #fff, 230px 1463px #fff, 650px 1440px #fff,
      130px 486px #fff, 1596px 1000px #fff, 595px 75px #fff, 38px 508px #fff,
      751px 689px #fff, 1191px 957px #fff, 1762px 350px #fff, 1871px 530px #fff,
      316px 1311px #fff, 761px 1853px #fff, 215px 341px #fff, 1955px 1355px #fff,
      1875px 1480px #fff, 1365px 429px #fff, 1543px 1213px #fff,
      1864px 1183px #fff, 1755px 1272px #fff, 1514px 700px #fff,
      1630px 1991px #fff, 514px 42px #fff, 280px 855px #fff, 1155px 699px #fff,
      1120px 1949px #fff, 603px 7px #fff, 1056px 100px #fff, 654px 746px #fff,
      1886px 364px #fff, 88px 1789px #fff, 1023px 761px #fff, 1904px 1570px #fff,
      1275px 1656px #fff, 1159px 644px #fff, 1993px 770px #fff,
      1016px 1004px #fff, 1990px 1945px #fff, 687px 1299px #fff,
      114px 1332px #fff, 371px 496px #fff, 700px 1897px #fff, 292px 1489px #fff,
      93px 1231px #fff, 423px 702px #fff, 1569px 229px #fff, 929px 1336px #fff,
      1863px 1026px #fff, 1177px 376px #fff, 1426px 790px #fff,
      816px 1698px #fff, 724px 65px #fff, 468px 1448px #fff, 1106px 230px #fff,
      1108px 1813px #fff, 911px 1730px #fff, 1155px 709px #fff,
      1341px 266px #fff, 171px 134px #fff, 1927px 1718px #fff,
      1028px 1726px #fff, 934px 278px #fff, 601px 134px #fff, 1537px 616px #fff,
      50px 764px #fff, 428px 148px #fff, 1568px 62px #fff, 1584px 287px #fff,
      911px 1506px #fff, 553px 907px #fff, 602px 1294px #fff, 657px 1313px #fff,
      6px 73px #fff, 467px 1030px #fff, 1201px 1660px #fff, 1784px 1198px #fff,
      1605px 84px #fff, 377px 1749px #fff, 463px 1423px #fff, 1725px 487px #fff,
      1874px 1493px #fff, 670px 1244px #fff, 1326px 1655px #fff,
      1394px 632px #fff, 208px 1424px #fff, 254px 1750px #fff, 405px 29px #fff,
      1258px 1710px #fff, 114px 336px #fff, 1698px 810px #fff, 640px 849px #fff,
      1312px 198px #fff, 1668px 1237px #fff, 1996px 669px #fff, 623px 110px #fff,
      1277px 1257px #fff, 1019px 24px #fff, 1923px 668px #fff, 1236px 23px #fff,
      1724px 1974px #fff, 1440px 605px #fff, 644px 1255px #fff,
      1762px 590px #fff, 1364px 992px #fff, 1025px 128px #fff,
      1320px 1078px #fff, 75px 721px #fff, 1013px 129px #fff, 716px 1896px #fff,
      1332px 289px #fff, 80px 567px #fff, 1019px 1878px #fff, 478px 769px #fff,
      705px 1515px #fff, 853px 375px #fff, 397px 1362px #fff, 635px 1664px #fff,
      147px 1402px #fff, 82px 1412px #fff, 1187px 645px #fff, 1480px 1429px #fff,
      521px 1610px #fff, 480px 290px #fff, 1673px 1481px #fff, 30px 1827px #fff,
      1989px 1416px #fff, 1458px 756px #fff, 401px 1px #fff, 1326px 121px #fff,
      1999px 1423px #fff, 1822px 48px #fff, 1443px 1029px #fff,
      240px 1102px #fff, 1740px 1232px #fff, 196px 1759px #fff,
      1551px 385px #fff, 1380px 954px #fff, 1889px 902px #fff,
      1559px 1998px #fff, 1636px 1425px #fff, 1021px 1001px #fff,
      435px 1440px #fff, 1309px 1391px #fff, 1531px 1976px #fff,
      711px 442px #fff, 904px 1922px #fff, 1894px 879px #fff, 606px 1391px #fff,
      550px 1284px #fff, 1500px 164px #fff, 1190px 182px #fff, 1544px 656px #fff,
      1237px 1133px #fff, 1436px 827px #fff, 1088px 745px #fff,
      1157px 1086px #fff, 852px 1998px #fff, 1839px 1246px #fff,
      570px 944px #fff, 1110px 1935px #fff, 1818px 1550px #fff,
      1093px 1251px #fff, 143px 714px #fff, 459px 985px #fff, 1931px 919px #fff,
      1132px 817px #fff, 588px 1539px #fff, 223px 1589px #fff, 730px 515px #fff,
      540px 206px #fff, 1607px 278px #fff, 1180px 1310px #fff, 679px 471px #fff,
      497px 1778px #fff, 813px 1343px #fff, 24px 1203px #fff, 584px 950px #fff,
      528px 105px #fff, 1060px 15px #fff, 1615px 303px #fff, 775px 876px #fff,
      1580px 1465px #fff, 1313px 454px #fff, 1282px 1666px #fff,
      1191px 1914px #fff, 939px 701px #fff, 1132px 1001px #fff,
      1057px 1577px #fff, 273px 360px #fff, 1123px 1694px #fff, 335px 999px #fff,
      1688px 1924px #fff, 973px 807px #fff, 1915px 1639px #fff, 188px 655px #fff,
      338px 1007px #fff, 1098px 1885px #fff, 270px 1786px #fff,
      1999px 1543px #fff, 378px 702px #fff, 43px 1044px #fff, 1512px 731px #fff,
      19px 1903px #fff, 687px 1422px #fff, 583px 268px #fff, 994px 1716px #fff,
      1841px 1657px #fff, 30px 873px #fff, 1202px 1872px #fff, 248px 407px #fff,
      594px 34px #fff, 846px 1050px #fff, 182px 902px #fff, 1469px 1209px #fff,
      642px 1385px #fff, 342px 165px #fff, 566px 1205px #fff, 958px 1568px #fff,
      1709px 328px #fff, 34px 1186px #fff, 1593px 566px #fff, 904px 219px #fff,
      72px 1291px #fff, 1798px 258px #fff, 759px 30px #fff, 1634px 1539px #fff,
      406px 1376px #fff, 61px 337px #fff, 386px 1270px #fff, 1589px 1040px #fff,
      84px 1133px #fff, 1609px 115px #fff, 1494px 1259px #fff, 822px 966px #fff,
      1039px 533px #fff, 1661px 1986px #fff, 1250px 1636px #fff,
      289px 451px #fff, 614px 637px #fff, 786px 1099px #fff, 1614px 852px #fff,
      718px 955px #fff, 134px 1424px #fff, 1672px 714px #fff, 1911px 333px #fff,
      195px 1387px #fff, 151px 542px #fff, 351px 169px #fff, 1921px 883px #fff,
      1626px 1927px #fff, 889px 973px #fff, 1148px 27px #fff, 1764px 304px #fff,
      1451px 119px #fff, 1032px 14px #fff, 33px 1481px #fff, 1056px 1390px #fff,
      1629px 413px #fff, 953px 244px #fff, 1865px 1278px #fff,
      1530px 1539px #fff, 840px 280px #fff, 1852px 1945px #fff, 356px 384px #fff,
      44px 47px #fff, 1986px 1337px #fff, 214px 1194px #fff, 741px 1715px #fff,
      1686px 967px #fff, 1974px 517px #fff, 924px 324px #fff, 1592px 714px #fff,
      1973px 488px #fff, 1602px 868px #fff, 448px 300px #fff;
  } */
`
export const StarsTwo = styled.div`
  display: block;
  position: absolute;
  width: 2.5px;
  height: 2.5px;
  border-radius: 80%;
  background: transparent;
  box-shadow: 788px 1310px #fff, 456px 1074px #fff, 512px 1893px #fff,
    52px 1113px #fff, 244px 612px #fff, 731px 762px #fff, 337px 386px #fff,
    342px 622px #fff, 1949px 753px #fff, 990px 1892px #fff, 1371px 1299px #fff,
    1029px 784px #fff, 1769px 98px #fff, 1090px 1326px #fff, 1825px 1079px #fff,
    701px 658px #fff, 1233px 1002px #fff, 954px 730px #fff, 1675px 1539px #fff,
    631px 685px #fff, 1094px 510px #fff, 860px 376px #fff, 1693px 1039px #fff,
    11px 438px #fff, 777px 610px #fff, 1659px 1394px #fff, 1394px 630px #fff,
    277px 1341px #fff, 851px 473px #fff, 737px 794px #fff, 1423px 389px #fff,
    1314px 216px #fff, 5px 464px #fff, 1271px 1056px #fff, 1662px 223px #fff,
    245px 645px #fff, 819px 1428px #fff, 1919px 495px #fff, 1903px 1311px #fff,
    1254px 741px #fff, 1945px 224px #fff, 1313px 1015px #fff, 1787px 779px #fff,
    153px 121px #fff, 100px 643px #fff, 725px 51px #fff, 460px 1433px #fff,
    1254px 741px #fff, 1945px 224px #fff, 1313px 1015px #fff, 1787px 779px #fff,
    153px 121px #fff, 100px 643px #fff, 725px 51px #fff, 460px 1433px #fff,
    331px 650px #fff, 854px 1305px #fff, 1377px 634px #fff, 1459px 834px #fff,
    1750px 1253px #fff, 879px 821px #fff, 435px 1764px #fff, 256px 1658px #fff,
    904px 1446px #fff, 26px 231px #fff, 464px 340px #fff, 1211px 467px #fff,
    1742px 439px #fff, 1568px 1155px #fff, 8px 47px #fff, 1400px 1722px #fff,
    1878px 131px #fff, 1938px 429px #fff, 731px 133px #fff, 371px 1407px #fff,
    1456px 105px #fff, 431px 1963px #fff, 1762px 645px #fff, 1637px 1417px #fff,
    1208px 1992px #fff, 1010px 1008px #fff, 11px 740px #fff, 1401px 1932px #fff,
    1134px 765px #fff, 1590px 1093px #fff, 1903px 1991px #fff, 685px 302px #fff,
    1183px 1275px #fff, 473px 1117px #fff, 918px 472px #fff, 1952px 1363px #fff,
    1189px 1946px #fff, 225px 1509px #fff, 383px 917px #fff, 1461px 1931px #fff,
    258px 893px #fff, 192px 1091px #fff, 1549px 1724px #fff, 1254px 2px #fff,
    1492px 1741px #fff, 279px 350px #fff, 1074px 561px #fff, 1073px 1px #fff,
    4px 1595px #fff, 1338px 684px #fff, 52px 122px #fff, 495px 1361px #fff,
    1747px 1762px #fff, 1890px 944px #fff, 830px 1332px #fff, 618px 1193px #fff,
    878px 1557px #fff, 809px 1512px #fff, 115px 1552px #fff, 1393px 11px #fff,
    21px 1575px #fff, 1121px 1243px #fff, 1690px 113px #fff, 1263px 1903px #fff,
    404px 1388px #fff, 391px 2000px #fff, 405px 1017px #fff, 1453px 457px #fff,
    530px 1563px #fff, 1002px 734px #fff, 1439px 1705px #fff, 1797px 1092px #fff,
    1024px 537px #fff, 1119px 677px #fff, 1517px 67px #fff, 587px 191px #fff,
    1519px 1549px #fff, 83px 241px #fff, 516px 1855px #fff, 1954px 953px #fff,
    307px 1970px #fff, 551px 1268px #fff, 1890px 1569px #fff, 544px 767px #fff,
    1838px 309px #fff, 199px 63px #fff, 1267px 323px #fff, 1221px 8px #fff,
    753px 1660px #fff, 1576px 1577px #fff, 1138px 1667px #fff, 1878px 131px #fff,
    1938px 429px #fff, 731px 133px #fff, 371px 1407px #fff, 1456px 105px #fff,
    431px 1963px #fff, 1762px 645px #fff, 1637px 1417px #fff, 1837px 1451px #fff,
    560px 851px #fff, 1310px 783px #fff, 730px 1839px #fff, 203px 899px #fff,
    466px 507px #fff, 1868px 1654px #fff, 450px 724px #fff, 307px 1358px #fff,
    710px 894px #fff, 1069px 293px #fff, 487px 1252px #fff, 1967px 1617px #fff,
    316px 1143px #fff, 297px 292px #fff, 649px 466px #fff, 655px 1795px #fff,
    1368px 1610px #fff, 313px 694px #fff, 1470px 1135px #fff, 1244px 96px #fff,
    1749px 1791px #fff, 1290px 1657px #fff, 1816px 155px #fff, 361px 682px #fff,
    1230px 1132px #fff, 892px 143px #fff, 1779px 1079px #fff, 1392px 991px #fff,
    1305px 1241px #fff, 1250px 1969px #fff, 171px 484px #fff, 1364px 695px #fff,
    1021px 1865px #fff, 1375px 1956px #fff, 1170px 94px #fff, 1084px 14px #fff,
    1910px 1897px #fff, 1300px 1185px #fff, 366px 1322px #fff, 1767px 410px #fff,
    519px 1316px #fff, 1321px 1248px #fff, 1974px 1057px #fff, 1679px 245px #fff,
    1860px 1582px #fff, 259px 1959px #fff, 86px 1625px #fff, 1683px 1494px #fff,
    852px 266px #fff, 1297px 432px #fff, 771px 434px #fff, 1236px 641px #fff,
    951px 415px #fff, 1903px 698px #fff, 994px 1037px #fff, 1859px 48px #fff,
    452px 160px #fff, 421px 1569px #fff, 1953px 1327px #fff, 498px 1936px #fff,
    562px 256px #fff;
  animation: animStar 150s linear infinite;
  /* &:after {
    content: " ";
    position: absolute;
    top: 100vh;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 788px 1310px #fff, 456px 1074px #fff, 512px 1893px #fff,
      52px 1113px #fff, 244px 612px #fff, 731px 762px #fff, 337px 386px #fff,
      342px 622px #fff, 1949px 753px #fff, 990px 1892px #fff, 1371px 1299px #fff,
      1029px 784px #fff, 1769px 98px #fff, 1090px 1326px #fff,
      1825px 1079px #fff, 701px 658px #fff, 1233px 1002px #fff, 954px 730px #fff,
      1675px 1539px #fff, 631px 685px #fff, 1094px 510px #fff, 860px 376px #fff,
      1693px 1039px #fff, 11px 438px #fff, 777px 610px #fff, 1659px 1394px #fff,
      1394px 630px #fff, 277px 1341px #fff, 851px 473px #fff, 737px 794px #fff,
      1423px 389px #fff, 1314px 216px #fff, 5px 464px #fff, 1271px 1056px #fff,
      1662px 223px #fff, 245px 645px #fff, 819px 1428px #fff, 1919px 495px #fff,
      1903px 1311px #fff, 1254px 741px #fff, 1945px 224px #fff,
      1313px 1015px #fff, 1787px 779px #fff, 153px 121px #fff, 100px 643px #fff,
      725px 51px #fff, 460px 1433px #fff, 331px 650px #fff, 854px 1305px #fff,
      1377px 634px #fff, 1459px 834px #fff, 1750px 1253px #fff, 879px 821px #fff,
      435px 1764px #fff, 256px 1658px #fff, 904px 1446px #fff, 26px 231px #fff,
      464px 340px #fff, 1211px 467px #fff, 1742px 439px #fff, 1568px 1155px #fff,
      8px 47px #fff, 1400px 1722px #fff, 1878px 131px #fff, 1938px 429px #fff,
      731px 133px #fff, 371px 1407px #fff, 1456px 105px #fff, 431px 1963px #fff,
      1762px 645px #fff, 1637px 1417px #fff, 1208px 1992px #fff,
      1010px 1008px #fff, 11px 740px #fff, 1401px 1932px #fff, 1134px 765px #fff,
      1590px 1093px #fff, 1903px 1991px #fff, 685px 302px #fff,
      1183px 1275px #fff, 473px 1117px #fff, 918px 472px #fff,
      1952px 1363px #fff, 1189px 1946px #fff, 225px 1509px #fff,
      383px 917px #fff, 1461px 1931px #fff, 258px 893px #fff, 192px 1091px #fff,
      1549px 1724px #fff, 1254px 2px #fff, 1492px 1741px #fff, 279px 350px #fff,
      1074px 561px #fff, 1073px 1px #fff, 4px 1595px #fff, 1338px 684px #fff,
      52px 122px #fff, 495px 1361px #fff, 1747px 1762px #fff, 1890px 944px #fff,
      830px 1332px #fff, 618px 1193px #fff, 878px 1557px #fff, 809px 1512px #fff,
      115px 1552px #fff, 1393px 11px #fff, 21px 1575px #fff, 1121px 1243px #fff,
      1690px 113px #fff, 1263px 1903px #fff, 404px 1388px #fff,
      391px 2000px #fff, 405px 1017px #fff, 1453px 457px #fff, 530px 1563px #fff,
      1002px 734px #fff, 1439px 1705px #fff, 1797px 1092px #fff,
      1024px 537px #fff, 1119px 677px #fff, 1517px 67px #fff, 587px 191px #fff,
      1519px 1549px #fff, 83px 241px #fff, 516px 1855px #fff, 1954px 953px #fff,
      307px 1970px #fff, 551px 1268px #fff, 1890px 1569px #fff, 544px 767px #fff,
      1838px 309px #fff, 199px 63px #fff, 1267px 323px #fff, 1221px 8px #fff,
      753px 1660px #fff, 1576px 1577px #fff, 1138px 1667px #fff,
      1837px 1451px #fff, 560px 851px #fff, 1310px 783px #fff, 730px 1839px #fff,
      203px 899px #fff, 466px 507px #fff, 1868px 1654px #fff, 450px 724px #fff,
      307px 1358px #fff, 710px 894px #fff, 1069px 293px #fff, 487px 1252px #fff,
      1967px 1617px #fff, 316px 1143px #fff, 297px 292px #fff, 649px 466px #fff,
      655px 1795px #fff, 1368px 1610px #fff, 313px 694px #fff,
      1470px 1135px #fff, 1244px 96px #fff, 1749px 1791px #fff,
      1290px 1657px #fff, 1816px 155px #fff, 361px 682px #fff,
      1230px 1132px #fff, 892px 143px #fff, 1779px 1079px #fff,
      1392px 991px #fff, 1305px 1241px #fff, 1250px 1969px #fff,
      171px 484px #fff, 1364px 695px #fff, 1021px 1865px #fff,
      1375px 1956px #fff, 1170px 94px #fff, 1084px 14px #fff, 1910px 1897px #fff,
      1300px 1185px #fff, 366px 1322px #fff, 1767px 410px #fff,
      519px 1316px #fff, 1321px 1248px #fff, 1974px 1057px #fff,
      1679px 245px #fff, 1860px 1582px #fff, 259px 1959px #fff, 86px 1625px #fff,
      1683px 1494px #fff, 852px 266px #fff, 1297px 432px #fff, 771px 434px #fff,
      1236px 641px #fff, 951px 415px #fff, 1903px 698px #fff, 994px 1037px #fff,
      1859px 48px #fff, 452px 160px #fff, 421px 1569px #fff, 1953px 1327px #fff,
      498px 1936px #fff, 562px 256px #fff;
  } */
`
