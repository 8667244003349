import React, { useContext, useRef, useEffect } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import useWindowSize from "../../hooks/resizeHook"

import ChooseAstronaut from "./ChooseAstronaut"

import {
  buttonBckg,
  H1,
  H2,
  H3,
  H4,
  LandingButton,
  Row,
} from "../../globalStyles"

import {
  FullContainerLocal,
  Col,
  StarsFour,
  StarsFive,
} from "./HomeWhyChooseUsStyle"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import useIsClient from "../../hooks/rehydration"

import { gsapScrollTrigger } from "../GSAP/gsap"

function HomeWhyChooseUs({ data }) {
  const { isDarkMode } = useContext(ThemeContext)
  const { isClient } = useIsClient()
  // const zero = [0, 0, 0, 0, 0, 0]
  // const [coordinateX, setCoordinateX] = useState(zero)
  // const [coordinateY, setCoordinateY] = useState(zero)

  const [width] = useWindowSize()

  // let parallaxAmountX = [-10, 10, -10, 10, -10, 10]
  // let parallaxAmountY = [-50, -50, -50, -50, -50, -50]

  // useLayoutEffect(() => {
  //   if (width <= 640) {
  //     setCoordinateX(parallaxAmountX)
  //     setCoordinateY(parallaxAmountY)
  //   } else if (width < 1024 && width >= 641) {
  //     setCoordinateY(parallaxAmountY)
  //     setCoordinateX(parallaxAmountX)
  //   }

  //   return () => {}
  // }, [width])
  const firstRef = useRef(null)
  firstRef.current = []
  const secondRef = useRef(null)
  secondRef.current = []

  const addToRefs = el => {
    if (
      el &&
      !firstRef.current.includes(el) &&
      !secondRef.current.includes(el)
    ) {
      firstRef.current.push(el)
    }
  }

  const addToRefTwo = el => {
    if (
      el &&
      !secondRef.current.includes(el) &&
      !firstRef.current.includes(el)
    ) {
      secondRef.current.push(el)
    }
  }

  useEffect(() => {
    // let jani = [...secondRef.current, ...firstRef.current]
    gsapScrollTrigger(firstRef.current, -200, 0, width, 15)
    // console.log()
    gsapScrollTrigger(secondRef.current, 200, 0, width, -15)

    // gsapScrollTrigger(jani, 200, -220)

    return () => {}
  }, [firstRef, width, secondRef])

  const mainItem = data.WhyChooseUs.edges[0].node

  const mainTitle = mainItem.landingHeader
  const mainPara = mainItem.landingParagraph.landingParagraph
  const landingButton = mainItem.landingButtonText

  const astroStatic = mainItem.astronautStatic[0]
  const planets = mainItem.landingDevices

  const miniCards = data.allContentfulChooseUsCards.nodes

  const smallTextBoxes = miniCards.map((x, i) => {
    return i % 2 === 0 ? (
      <Col
        font
        sm
        key={i}
        // ref={addToRefs}
        ref={addToRefs}
        className="egy col-12 col-md-6 col-lg-6"
      >
        <div className="row justify-content-center ">
          {width < 1023 && isClient && (
            <Col className="col-3 align-self-center">
              {/* <Parallax x={[coordinateX[i], 0]} y={[coordinateY[i], 0]}>
                <img
                  style={{
                    width: "65%",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.2)",
                  }}
                  src={x.svg.file.url}
                  alt={x.svg.title}
                />
              </Parallax> */}
            </Col>
          )}
          <Col className="col-9 mb-2 col-md-10 col-lg-11">
            <H2
              style={{
                display: "inline-block",
                borderBottom: `1px solid ${
                  isDarkMode ? "rgba(255,255,255,0.6)" : buttonBckg.dark
                }`,
                fontWeight: "bold",
              }}
              className="mb-2"
            >
              {x.title}
            </H2>
            <H4 choose={"true"}>{x.para.para}</H4>
          </Col>
        </div>
      </Col>
    ) : (
      <Col
        font
        key={i}
        ref={addToRefTwo}
        // ref={addToRefs}
        sm
        className="ketto col-12 col-md-6   col-sm-9  "
      >
        <div className="row mb-2 justify-content-center ">
          {width > 641 && width < 1024 && isClient && (
            <Col className="col-3 ">
              {/* <Parallax x={[coordinateX[i], 0]} y={[coordinateY[i], 0]}>
                <img
                  style={{
                    width: "65%",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.2)",
                  }}
                  src={x.svg.file.url}
                  alt={x.svg.title}
                />
              </Parallax> */}
            </Col>
          )}
          <Col className="col-9 col-md-10 col-lg-11">
            <H2
              style={{
                display: "inline-block",
                borderBottom: `1px solid ${
                  isDarkMode ? "rgba(255,255,255,0.6)" : buttonBckg.dark
                }`,
                fontWeight: "bold",
              }}
              className="mb-2"
            >
              {x.title}
            </H2>
            <H4 choose={"true"}>{x.para.para}</H4>
          </Col>{" "}
          {width < 641 && (
            <Col className="col-3 align-self-center">
              {/* <Parallax x={[coordinateX[i], -10]} y={[coordinateY[i], -20]}>
                <img
                  style={{
                    width: "65%",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.2)",
                  }}
                  src={x.svg.file.url}
                  alt={x.svg.title}
                />
              </Parallax> */}
            </Col>
          )}
        </div>
      </Col>
    )
  })

  return (
    <FullContainerLocal dark={isDarkMode} className="container-fluid ">
      {/* {isDarkMode ? <StarsFive /> : <StarsFour />} */}
      {isDarkMode ? (
        <Parallax y={[300, 30]}>
          <StarsFive />
        </Parallax>
      ) : (
        <Parallax y={[300, 30]}>
          <StarsFour />
        </Parallax>
      )}
      <Row>
        {width >= 768 && width < 1000 ? (
          <div className="row text-center ">
            <ChooseAstronaut planets={planets} astronaut={astroStatic} />
            <div className="col-md-6 mb-2">
              <Col
                style={{
                  borderRadius: "5%",
                  marginTop: "10%",

                  // IPAD RENDER
                  // IPAD RENDER
                }}
                className="col-12  col-md-12 col-sm-8  "
              >
                <H1>{mainTitle}</H1>
                <H3 className="mt-5 mb-3  p-0">{mainPara}</H3>
                <Link to="/services">
                  <LandingButton dark={isDarkMode} loaded>
                    <H4>{landingButton}</H4>
                  </LandingButton>
                </Link>
              </Col>
            </div>

            <div className="row text-center justify-content-around">
              {smallTextBoxes}
            </div>
          </div>
        ) : (
          <div className="row text-center">
            <ChooseAstronaut planets={planets} astronaut={astroStatic} />
            <div className="col-md-7   ">
              <Col
                style={{
                  borderRadius: "5%",
                  marginTop: "5%",
                }}
                className="col-12 mx-auto col-md-12 col-sm-8 "
              >
                <H1>{mainTitle}</H1>
                <H3 className=" p-0 mt-5 col-lg-11 col-md-10 col-12 mr-auto ml-auto mb-3">
                  {mainPara}
                </H3>
                <Link to="/services">
                  <LandingButton className="mx-auto" dark={isDarkMode} loaded>
                    <H4>{landingButton}</H4>
                  </LandingButton>
                </Link>
              </Col>
              <div className="row mt-4 mb-3 text-center justify-content-center">
                {smallTextBoxes}
              </div>
            </div>
          </div>
        )}
      </Row>
    </FullContainerLocal>
  )
}

export default HomeWhyChooseUs
