// import Layout from "../Layout"
import React, { useState, useContext, useEffect } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"
// import astronaut from "../../../images/astronaut.png"
// import planet from "../../../images/internet.svg"
// import desktop from "../../../images/desktop.svg"
// import tablet from "../../../images/tablet.svg"
// import smartphone from "../../../images/smartphone.svg"
// import laptop from "../../../images/laptop.svg"
import { FullContainer } from "../../globalStyles"
import useWindowSize from "../../hooks/resizeHook"
import MatterJs from "./Matter"
import Welcome from "./Welcome"
import Astronaut from "./Astronaut"

import { StarsOne, StarsTwo } from "./JumbotronStyle"

function Jumbotron({ data }) {
  const items = data.allContentfulJumbotronPage.edges[0].node

  const {
    landingDevices,
    astronautStatic,
    landingButtonText,
    landingHeader,
    landingParagraph,
    dialog,
  } = items

  const [width, height] = useWindowSize()
  const { isDarkMode } = useContext(ThemeContext)
  const [loaded, setLoaded] = useState(false)
  //

  //
  function timeout(value, time, clean) {
    setTimeout(() => {
      setLoaded(value)
    }, time)
  }

  useEffect(() => {
    let timeoutShow
    let timeoutHide
    //dialog appears
    let appearTime = 3000
    timeoutShow = setTimeout(() => {
      setLoaded(true)
    }, appearTime)

    //dialog disappears 30s + appears time
    timeoutHide = setTimeout(() => {
      setLoaded(false)
    }, appearTime + 30000)
    return () => {
      setLoaded(true)
      clearTimeout(timeoutShow)
      clearTimeout(timeoutHide)
    }
  }, [])

  let isDark = isDarkMode ? "light" : "dark"
  return (
    <FullContainer
      onClick={() => {
        timeout(false, 1000)
        // console.log("click")
      }}
      onTouchEnd={() => {
        timeout(false, 1000)
        // console.log("touchend")
      }}
      className="container-fluid"
      primary={isDark}
    >
      <StarsOne />
      {!isDarkMode && <StarsTwo />}
      <div className="row">
        <Welcome
          buttonTxt={landingButtonText}
          header={landingHeader}
          para={landingParagraph.landingParagraph}
          isDarkMode={isDarkMode}
        />
        {width > 640 && (
          <MatterJs
            desktop={landingDevices[1].file.url}
            smartphone={landingDevices[0].file.url}
            laptop={landingDevices[2].file.url}
            tablet={landingDevices[3].file.url}
            width={width}
            height={height}
          />
        )}
        <Astronaut
          dark={isDarkMode}
          imgAlt={landingDevices}
          desktop={landingDevices[1].file.url}
          smartphone={landingDevices[0].file.url}
          laptop={landingDevices[2].file.url}
          tablet={landingDevices[3].file.url}
          astronaut={astronautStatic[0]}
          satellite={astronautStatic[1]}
          width={width}
          loaded={loaded}
          dialog={dialog}
        />
      </div>
    </FullContainer>
  )
}

export default Jumbotron
